import { useContext, useState } from 'react';
import { Button, LinkButton, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { useTranslation } from '@locale/Translator';
import { DefaultModal } from '@molecules/Modal/DefaultModal';
import { SessionContext } from '@shared/contexts/SessionContext/sessionContext';

import { PlayerModal } from '../PlayerModa/PlayerModal';
import * as SC from './styled';

interface IProps {
  open: boolean;
  onClose?(): void;
  onAction(): void;
}

const FIRST_STEP = 0;

export function CompletionRegistrationModal({ open, onAction, onClose }: IProps) {
  const { t } = useTranslation();
  const { isAdmin } = useContext(SessionContext);
  const [step, setStep] = useState(FIRST_STEP);
  const context = 'molecules.acceptSubscriptionCheckbox.feedbacks.success';

  const [videoOpen, setVideoOpen] = useState(false);

  const renderHeaderTitle = () => (
    <SC.ModalDescriptionContainer>
      {step === FIRST_STEP ? (
        t(`${context}.title.stepOne`)
      ) : (
        <Typography variant="headline8">{t(`${context}.title.stepTwo`)}</Typography>
      )}
    </SC.ModalDescriptionContainer>
  );

  const renderHeaderSubtitle = () => {
    const [doubts, link] = t(`${context}.caption.stepTwo.doubts`).split(',');

    return (
      <SC.ModalDescriptionContainer>
        <PlayerModal open={videoOpen} onClose={() => setVideoOpen(false)} />
        {step === FIRST_STEP ? (
          t(`${context}.caption.stepOne`)
        ) : (
          <>
            <Typography variant="body3">{t(`${context}.caption.stepTwo.attention`)}</Typography>
            <SC.List>
              <li>
                <Typography variant="body3">{t(`${context}.caption.stepTwo.addEmployee`)}</Typography>
              </li>
              <li>
                <Typography variant="body3">
                  {`${doubts}, `}
                  <LinkButton variant="default" onClick={() => setVideoOpen(true)}>
                    {link}
                  </LinkButton>
                </Typography>
              </li>
            </SC.List>
          </>
        )}
      </SC.ModalDescriptionContainer>
    );
  };

  const renderFooterButtons = () => {
    if (!isAdmin) {
      return (
        <SC.ModalButtonContainer>
          <Button size="large" variant="primary" onClick={onAction}>
            {t(`${context}.modalButton.start`)}
          </Button>
        </SC.ModalButtonContainer>
      );
    }

    return (
      <SC.ModalButtonContainer>
        {step === FIRST_STEP ? (
          <Button size="large" variant="primary" onClick={() => setStep(step + 1)}>
            {t(`${context}.modalButton.next`)}
          </Button>
        ) : (
          <>
            <div>
              <LinkButton variant="default" onClick={onAction}>
                {t(`${context}.modalButton.skip`)}
              </LinkButton>
            </div>
            <Button size="large" variant="primary" onClick={() => window.open('/settings/permissions', '_top')}>
              {t(`${context}.modalButton.reviewPermission`)}
            </Button>
          </>
        )}
      </SC.ModalButtonContainer>
    );
  };

  return (
    <DefaultModal
      headerIcon={step === FIRST_STEP ? 'IconCheck' : 'IconAlertSmall'}
      headerIconLabel={t(`${context}.labelIcon.${step === FIRST_STEP ? 'stepOne' : 'stepTwo'}`)}
      headerTitle={renderHeaderTitle()}
      headerSubtitle={renderHeaderSubtitle()}
      open={open}
      footerCustom={renderFooterButtons()}
      onCloseClick={onClose}
    />
  );
}
