import { LocaleKeys } from './localeKeys';

const translations: LocaleKeys = {
  translations: {
    page: {
      helloworld: 'Hello expense :D',
    },
    general: {
      services: {
        feedback: {
          error_403: 'You do not have access to this operation',
        },
      },
      helper: {
        optionNotRegistered: 'Name not registered',
      },
    },
    atoms: {
      checkboxList: {
        apply: 'Apply',
        clean: 'Clean',
        empty: 'No item found!',
      },
    },
    molecules: {
      advancesStatus: {
        open: 'Open',
        finished: 'Finished',
        submitted: 'Submitted',
        reproved: 'Reproved',
        approved: 'Approved',
        pending: 'Pending',
        awaiting_financial_processing: 'Awaiting financial processing',
        processing_payment: 'Awaiting payment',
        processing_payment_error: 'Payment error',
      },
      feedbackBox: {
        dismiss: 'close',
        title: {
          info: {
            clients: 'Clients import in progress',
            costCenters: 'Costs centers import in progress',
          },
          error: {
            clients: 'Processing ended. Some clients could not be imported.',
            costCenters: 'Processing ended. Some cost centers could not be imported.',
            projects: 'Processing ended. Some projects could not be imported.',
          },
          success: 'Importing ended successfully',
        },
        description: {
          error: {
            fail: {
              clients: {
                plural: '{{quantity}} clients could not be imported. You should retry uploading these clients.',
                singular: '{{quantity}} client could not be imported. You should retry uploading this client.',
              },
              costCenters: {
                plural: '{{quantity}} costs centers have an error in the registration, preventing imports.',
                singular: '{{quantity}} cost center has an error in the registration, preventing import.',
              },
              projects: {
                plural: '{{quantity}} projects have an error in the registration, preventing imports.',
                singular: '{{quantity}} project has an error in the registration, preventing import.',
              },
            },
            completed: {
              clients: {
                singular: '{{quantity}} client has correct data for registrations.',
                plural: '{{quantity}} clients have correct data for registration.',
              },
              costCenters: {
                singular: '{{quantity}} cost center has correct data for registrations.',
                plural: '{{quantity}} costs centers have correct data for registration.',
              },
              projects: {
                singular: '{{quantity}} project has correct data for registrations.',
                plural: '{{quantity}} projects have correct data for registration.',
              },
            },
          },
          info: 'This process can take a while',
          success: {
            clients: '{{quantity}} clients registered successfully. Check on the table below the imported clients.',
            costCenters:
              '{{quantity}} costs centers registered successfully. Check on the table below the imported clients.',
            projects: '{{quantity}} projects registered successfully. Check on the table below the imported clients.',
          },
        },
        actionLabel: {
          error: 'See the errors',
        },
      },
      bulkImportDropzone: {
        loadFile: {
          subTitle: 'File loaded successfully',
        },
        sendFile: {
          yourFile: 'Your file',
        },
      },
      costCenterToast: {
        updateErrorMessage: 'Error trying to update cost center',
        updateSuccessMessage: 'Cost center updated successfully',
        uploadErrorMessage: 'Error trying to upload cost centers',
      },
      projectToast: {
        uploadErrorMessage: 'Error trying to upload projects',
      },
      underConstruction: {
        description: 'Under construction!',
        toGoBack: 'To go back',
      },
      importHelpModal: {
        title: 'Need help with your file?',
        subTitle: 'In case of any doubt or error, send your file to support.',
        actionButton: 'Send',
        input: {
          goal: 'Goal of the file',
          description: 'Describe your problem and how we can help',
          max: 'Maximum of 100 characters',
        },
        toast: {
          success: {
            title: 'Help support to file created successfully!',
            subTitle: 'Check the protocol number sent to your e-mail.',
          },
        },
        dropzone: 'Your file',
      },
      clientToast: {
        getClientsTemplateErrorMessage: "Error trying to retrieve client's template file",
        getClientsErrorMessage: 'Get clients Error',
        createErrorMessageTitle: 'Error trying to adding client',
        createErrorMessage: 'The information were not saved by an system error, please try again.',
        createSuccessMessage: 'Client successfully added!',
        deleteErrorMessage: {
          singularForm: 'Error trying to delete client',
          pluralForm: 'Error trying to delete clients',
        },
        deleteSuccessMessage: {
          singularForm: 'Client deleted successfully',
          pluralForm: 'Clients deleted successfully',
        },
        updateErrorMessage: 'Error trying to update client',
        updateSuccessMessage: 'Client updated successfully',
        uploadErrorMessage: 'Error trying to upload clients',
      },
      deleteClientModal: {
        confirm: 'Confirm deletion',
        subTitle: {
          singularForm: 'All the data related to it will be lost and this action cannot be undone.',
          pluralForm: 'All the data related to them will be lost and this action cannot be undone.',
        },
        title: {
          singularForm: 'Are you sure you want to delete this client?',
          pluralForm: 'Are you sure you want to delete {{quantity}} client?s',
        },
        attention: 'Attention!',
      },
      inactivateClientModal: {
        subTitle: {
          singularForm: {
            deactivate: "When inactive, this client won't be show on clients list when registering an expense.",
            activate: 'When active, this client will be show on clients list when registering an expense.',
          },
          pluralForm: {
            deactivate: "When inactive, these clients won't be show on clients list when registering an expense.",
            activate: 'When active, these clients will be show on clients list when registering an expense.',
          },
        },
        title: {
          singularForm: {
            deactivate: 'Are you sure you want to inactivate this client?',
            activate: 'Are you sure you want to activate this client?',
          },
          pluralForm: {
            deactivate: 'Are you sure you want to inactivate {{quantity}} clients?',
            activate: 'Are you sure you want to activate {{quantity}} clients?',
          },
        },
        attention: 'Attention!',
        confirm: {
          activate: 'Confirm activation',
          deactivate: 'Confirm inactivation',
        },
      },
      moreFilters: {
        title: 'Filters',
        labels: {
          startDate: 'Start date',
          endDate: 'End date',
          creationDate: 'Creation date',
          occurrences: 'Occurrences',
          cardNumber: 'Corporate card number',
          requester: 'Requester',
          branch: 'Branch',
          area: 'Area',
          position: 'Position',
          costCenter: 'Cost center',
          client: 'Client Name',
          project: 'Project',
          reimbursableValue: 'Reimbursable value',
          reportName: 'Report name',
          lastApprover: 'Last approver',
          paymentDate: 'Payment date',
          value: 'Value',
        },
      },
      setupIntroduction: {
        hello: 'Hello, {{userName}}',
        title: 'Have full control of corporate expenses',
        description:
          "It wont be long before you begin the revolution in managing your company's corporate expenses. Configure the Flash Expenses module in just a few clicks.",
        skipConfiguration: 'Skip settings',
        configureNow: 'Configure now',
      },
      setupInviteEmployees: {
        finish: 'All ready, {{userName}}!',
        title: 'Your company can now start revolutionizing expenses',
        description: 'The time has come, you can send an invitation to registered employees for their first access.',
        skipInvite: 'Not now',
        inviteEmployees: 'Invite collaborators',
        actionsDescriptions: 'If you prefer, you can send the invitation later.',
      },
      categoryForm: {
        codeLabel: 'Code',
        codeHelp: 'Use the code to integrate with your ERP system',
        descriptionLabel: 'Description',
        isActiveLabel: 'Active',
        cancel: 'Cancel',
        createSubmit: 'Create',
        updateSubmit: 'Update',
      },
      limitsForm: {
        spendingPolicies: 'Spending policy',
        releaseCriteriaLabel: 'Launch criteria',
        criterion: {
          day: 'Day',
          expense: 'Expense',
          quantity: 'Quantity',
          month: 'Month',
        },
        currencyTypeLabel: 'Moeda',
        limitValueLabel: 'Valor limite',
        settings: 'Configurações',
        allowValueGreaterThanLimitLabel: 'Bloquear envio de despesa com valor maior que o limite',
        allowValueGreaterThanLimitHelp:
          'Caso o valor de uma despesa seja maior que o valor limite, o envio não será realizado e o colaborador será notificado.',
        commentRequiredLabel: 'Campo de comentário obrigatório',
        proofMandatory: 'Comprovante obrigatório',
        minimumInvoiceProofValueLabel: 'A partir de',
        minimumInvoiceProofValueHelp: 'Comprovante obrigatório em despesas com valor igual ou superior ao informado.',
      },
      actionsFeedback: {
        occurrence: 'occurrence',
        occurrences: 'occurrences',
      },
      gridPagination: {
        items: 'items',
      },
      defaultModal: {
        cancel: 'Cancel',
        confirm: 'Confirm',
      },
      modalReportSelection: {
        selectReport: 'Select report',
        select: 'Select',
        createReport: 'Create report',
        selectTheReportYouWantToAddTheExpenseToOrCreateANewReport:
          'Select the report you want to add the expense to, or create a new report.',
        report: 'Report',
        noReportsFound: 'No reports found',
      },
      receiptsImages: {
        receipt: 'Receipt',
        add: 'Add receipt',
      },
      linkedReport: {
        add: 'Add report',
        report: 'Report',
      },
      expenseCollapse: {
        establishment: 'Establishment',
        value: 'Value',
        date: 'Date',
        toRecall: 'To recall',
        seeDetails: 'See details',
        client: 'Client',
        project: 'Project',
        category: 'Category',
        costCenter: 'Cost center',
        comments: 'Comment',
        uninformed: 'Uninformed',
        report: 'Report',
        quantity: 'Quantity',
      },
      occurrencesBox: {
        dismiss: 'Dismiss',
      },
      reportStatusCard: {
        filter: 'filter',
        removeFilter: 'Remove filter',
        pluralForm: {
          open: 'Open',
          reimbursed: 'Reimbursed',
          submitted: 'Submitted',
          reproved: 'Failed',
          approved: 'Approved reports',
        },
        singularForm: {
          open: 'Open',
          reimbursed: 'Reimbursed',
          submitted: 'Subject',
          reproved: 'Disapproved',
          approved: 'Approved report',
        },
      },
      newReportModal: {
        createTitle: 'Create report',
        updateTitle: 'Update report',
        create: 'Create',
        update: 'Update',
        description: 'Reason',
        reportName: 'Report name',
        reportApprover: 'Approver',
      },
      reportToast: {
        createErrorMessage: 'Error while creating report',
        createSuccessMessage: 'Report successfully created',
        updateSuccessMessage: 'Report successfully updated',
        updateErrorMessage: 'Error while updating report',
        deleteSuccessMessage: 'Report successfully deleted',
        attachExpenseSuccessMessage: 'Expense successfully attached to report',
      },
      deleteReportModal: {
        attention: 'Attention',
        enterTheJustification: '',
        justifyTheDisapproval: '',
        popupTitle: 'Are you sure you want to delete this report?',
        popupSubtitle: 'All expenses will be unattached to this report.',
        confirmDeletion: 'Confirm deletion',
        attachExpenseSuccessMessage: 'Expense successfully attached to report',
      },
      gridFilters: {
        labelSearchField: 'Search',
        filterListLabel: 'filter by',
      },
      addLimitToCategoryModal: {
        singleTitle: 'Add limit to one category',
        sharedTitle: 'Add shared limit',
        singleSubtitle: 'Create one expense limit for only one category.',
        sharedSubtitle:
          'Create a shared limit to the categories selected. The limit will be applied on the sum of the expenses value on different categories',
        editSubtitle: 'Edit expense limit to category.',
        editTitle: 'Edit limit',
        edit: 'Save',
        add: 'Add',
        updateSuccessMessage: 'Limit successfully updated.',
        categoryLabel: 'Category',
        releaseCriterionLabel: 'Release criterion',
        criterion: {
          day: 'Day',
          expense: 'Expense',
          quantity: 'Quantity',
          month: 'Month',
        },
        currencyLabel: 'Currency',
        limitValueLabel: 'Limit value',
        settings: 'Settings',
        allowValueGreaterThanLimitLabel: 'Block sending expense with value greater than limit',
        allowValueGreaterThanLimitHelp:
          'If expense value of one expense is greater than limit, it will be not sent and the employee will be notified.',
        commentRequiredLabel: 'Comment field required',
        proofMandatory: 'Proof required',
        minimumInvoiceProofValueLabel: 'Minimum invoice proof value',
        minimumInvoiceProofValueHelp: 'Required invoice at expenses with value greater or equal to informed',
        tooltipAction: {
          single: 'Select a category and set a value to continue!',
          shared: 'Select one or more categories and set a value to continue!',
          receiptRequired: 'Fill in the required voucher amount in expenses!',
        },
      },
      deleteLimitConfirmModal: {
        iconLabel: 'Attention',
        title: {
          plural: 'Are you sure you want to delete these {{quantity}} limits?',
          singular: 'Are you sure you want to delete this limit?',
        },
        subtitle: {
          plural: 'All of the data related to them will be lost and this action cannot be undone.',
          singular: 'All of the data related to it will be lost and this action cannot be undone.',
        },
        delete: 'Confirm deletion',
      },
      deleteMileageRateConfirmModal: {
        iconLabel: 'Attention',
        title: {
          plural: 'Are you sure you want to delete km rates?',
          singular: 'Are you sure you want to delete the km rate?',
        },
        subtitle: {
          plural: 'All data related to them will be lost and this action cannot be undone.',
          singular: 'All data related to it will be lost and this action cannot be undone.',
        },
        delete: 'Confirm deletion',
      },
      mileageRateRegisterModel: {
        description: 'Set the value per kilometer driven.',
        create: {
          title: 'Add mileage rate',
          submit: 'Add',
        },
        update: {
          title: 'Edit mileage rate',
          submit: 'Save',
        },
        fields: {
          startDate: 'Start date',
          value: 'Value',
        },
      },
      selectLimitTypeModal: {
        title: 'Add limit',
        subtitle: 'Select how you want to add limit to the category',
        single: {
          title: 'Limit for one category',
          description: 'Create an expense limit for one category only.',
        },
        shared: {
          title: 'Shared Limit',
          description:
            'Create a limit for the selected. The limit will be applied on the sum of the expenses on different categories.',
        },
        continue: 'Continue',
      },
      addLimitSuccessModal: {
        conclude: 'Conclude',
        continue: 'Continue adding',
        singular: {
          title: 'Limit added successfully',
          subtitle: 'The limit to the category {{category}} was added.',
        },
        plural: {
          title: 'Limits added successfully',
          subtitle: 'The limit to the categories "{{category}}" was added.',
        },
      },
      expenseSettings: {
        feedbacks: {
          updated: {
            success: 'Setting updated successfully',
            error: 'Setting updated error',
          },
        },
      },
      generalSettings: {
        title: 'General settings',
        description: 'Edit basic settings that interfere with posting expenses.',
        fieldsLabel: {
          expirationExpenses: 'Expenses expire in',
          manualExpenseEntry: 'Allow posting of manual expenses',
          daysExpirationExpenses: 'Days',
          allowLaterDate: 'Allow expense submissions with a later date',
          allowEarlierDate: 'Allow past-dated expense submissions',
        },
      },
      fieldCustomization: {
        title: 'Field customization',
        description: 'Customize the fields that will be displayed and required when posting expenses.',
        enabledField: 'Enabled field',
        disabledField: 'Disabled field',
        mandatoryField: 'Mandatory field',
        mandatory: 'Mandatory',
        nonMandatory: 'Non-mandatory',
        mandatoryFieldInfo: 'Mandatory field',
        fields: {
          quantity: 'Quantity',
          category: 'Category',
          address: 'Establishment',
          receiptFile: 'Receipt',
          currency: 'Currency',
          paymentType: 'Payment type',
          value: 'Value',
          date: 'Date',
          description: 'Comment',
          invoice: 'Voucher No.',
          reimbursableExpenses: 'Reimbursable expenses',
          notifyPendingExpenses: 'Notify pending expenses',
          client: 'Client',
          invoiceKey: 'Invoice key',
          project: 'Project',
          costCenter: 'Cost center',
          billable: 'Billable',
        },
        feedback: {
          error: 'An error occurred when requesting the field: {{ field }}.',
        },
      },
      contractsModal: {
        expenses: {
          title: 'Commercial Conditions Flash Expense',
        },
        corporateCard: {
          title: 'Commercial Conditions Corporate Card',
        },
        subtitle:
          'To confirm your subscription, you must carefully read and accept the Commercial Terms and Conditions of the Contract.',
        confirmation: 'I have read and accepted',
        close: 'close',
      },
      acceptSubscriptionCheckbox: {
        iHaveReadEndAcceptThe: 'I have read end accept the',
        commercialConditions: 'commercial conditions',
        fromFlashServices: 'from Flash services related to',
        products: {
          corporateCard: 'cartão corporativo',
          saas: 'software para gestão de despesas (”SAAS”)',
          travel: 'gerenciamento das viagens corporativas',
        },
        common: {
          and: 'and',
        },
        feedbacks: {
          error: {
            companyRevoked:
              'You should not sign a new expense contract for this CNPJ, as you have an active registration on the old platform. Continue using the old system until your company migrates to the new one.',
            employeeInactive:
              'The subscription cannot be carried out because this user is not active. Perform user activation and try again.',
            saasContractNotFound:
              'The signature cannot be carried out because the SAAS Agreement was not loaded correctly. If the problem persists, contact support.',
            corporateCardContractNotFound:
              'The subscription cannot be carried out as the Corporate Card Agreement was not loaded correctly. If the problem persists, contact support.',
            default: 'An error occurred while registering acceptance of the terms, please try again.',
          },
          success: {
            labelIcon: { stepOne: 'Congratulations!', stepTwo: 'Important' },
            title: {
              stepOne: 'Your Flash Expense subscription is confirmed ⚡',
              stepTwo:
                'Before starting to use our services, review who will be the expense administrator by assigning this profile to employees already registered on the platform.',
            },
            caption: {
              stepOne: 'Starting today, you can boost your expense management with the smartest tool on the market',
              stepTwo: {
                addEmployee: 'If the employee is not registered, go to "Team" and complete the registration first.',
                attention: 'Attention',
                doubts: 'If you have any doubts about assigning a profile, watch this video tutorial.',
              },
            },
            modalButton: {
              startUsing: 'Get started',
              next: 'Next',
              reviewPermission: 'Review permissions',
              skip: 'Skip and init',
              start: 'Get started',
            },
            videoTitle: 'How to assign permissions to the employee',
          },
        },
      },
      creditCardForm: {
        title: 'Insert your credit card',
        subtitle: 'Your subscription will be debited every 5th of the month, on the card below.',
        labelFields: {
          cardholderName: 'Cardholder name',
          cardNumber: 'Ccard number',
          dueDate: 'Due date',
        },
      },
      subscriptionInformation: {
        valueCaption: 'person/month',
      },
      subscriptionPriceBox: {
        title: 'Your Flash Expense subscription on the {{planName}} plan',
        free: 'Free',
        perPersonMonth: 'per person/month',
        minimumPeoplePerMonth: 'Minimum users quantity per month: {{quantity}} users',
      },
      pageHeader: {
        faq: {
          expenses: 'Ask questions and find out more about accountability',
          reports: 'Ask questions and learn more about reports',
          accounting: 'Ask questions and learn more about accounting',
          customizations: 'Ask questions and learn more about preferences',
        },
      },
      addNewStopRoute: {
        buttonLabel: 'Add a stopover or final destination',
      },
      distanceRoutes: {
        distance: 'Distance',
        ratePerKM: 'Rate per KM',
      },
      expenseDetailsKilometer: {
        distance: 'Distance',
        ratePerKM: 'Rate per kilometer',
        totalValue: ' Total value',
      },
      odometerUpload: {
        label: {
          start: 'start_odometer',
          final: 'final_odometer',
        },
      },
      subscriptionFeatures: {
        title: 'Services included',
        corporateCard: 'Corporate Flash Card',
        refundRequest: 'Refund request',
        expenseAccountsProvision: 'Provision of expense accounts',
        automaticApprovals: 'Automatic Approvals',
        companyWidePolicy: 'Company wide policy',
        managementReport: 'Management report',
        kmRefund: 'Refund per mileage',
        companyPolicy: 'Company level policy',
        erpIntegration: 'Integration with ERP (Omie)',
        multipleErpIntegration: 'Integration with the main ERPs on the market',
        moreFeatures: 'And much more (see contract)',
        automaticManualApprovals: 'Automatic and manual approvals',
        companyWideApprovals: 'Multi-level and custom approvals',
        companyCustomPolicy: 'Multi-level and personalized policies',
        advances: 'Advances',
        travel: {
          corporateTravels: 'Corporate travels',
          airfareAndHotelQuotes: 'Airfare and hotel quotes',
          domesticAndInternationalTickets: 'Domestic and international tickets',
          domesticAndInternationalHotelReservations: 'Domestic and international hotel reservations',
          approvalFlow: 'Approval flow',
          policyConfiguration: 'Policy configuration',
          detailedReports: 'Detailed reports',
        },
      },
      newFeatureTag: {
        new: 'New!',
      },
    },
    organisms: {
      subsidiaryRegister: {
        fields: {
          name: 'Subsidiary name',
          status: 'Active',
          cnpj: 'Register number',
          uf: 'State',
          code: 'Code',
          costCenters: 'Cost centers',
          address: 'Address',
          number: 'Number',
          complement: 'Complement',
          neighborhood: 'Neighborhood',
          city: 'City',
          state: 'State',
          zipCode: 'Zip Code',
          activeSubsidiary: {
            title: 'Active subsidiary',
            description: 'When active, the business unit can be linked to a user.',
          },
          requiredField: 'Required field',
        },
        editSubsidiary: 'Edit subsidiary',
        manualAddSubsidiary: 'Register subsidiary',
        pageContent: [
          {
            title: 'General data',
            sectionTitle: 'General data',
            subTitle: 'Please fill the information to register a subsidiary.',
          },
          {
            title: 'Assign people',
            sectionTitle: 'Select people',
            subTitle: 'Link people to the business unit.',
          },
        ],
        sections: {
          costCenterInfo: 'Cost center',
          addressInfo: 'Address',
          generalData: 'General data',
        },
      },
      advancesApproval: {
        filters: {
          status: 'Status',
          referenceId: 'Search by code',
          statusLabel: {
            open: 'Open',
            finished: 'Finished',
            submitted: 'Submitted',
            reproved: 'Reproved',
            approved: 'Approved',
            pending: 'Pending',
          },
        },
      },
      registerCostCenter: {
        bulkImport: {
          sendData: {
            title: 'Send data',
            subTitle:
              'Send customer data via spreadsheet or text file for batch registration. In the next steps you will be able to check everything and correct possible errors.',
          },
          fileModel: {
            title: 'Download the flash model and insert your data',
            subTitle: 'To avoid errors, it accepted only file in flash model format.',
          },
          sendFile: {
            title: 'Send your file',
            subTitle: 'With your file, now you just need to upload your file.',
          },
          help: {
            title: 'Need help with your file?',
            subTitle: 'In case of errors or help is needed, send your file to our support team.',
            actionButton: 'Send your file to support',
          },
          breadcrumbs: {
            manageCompany: 'Manage company',
            addByFile: 'Add by file',
          },
          footer: {
            continue: 'Continue',
            cancel: 'Cancel',
          },
        },
      },
      advances: {
        delete: {
          errorMessage: 'An error occurred while trying to delete advances',
          successMessage: {
            plural: 'Advances delete successfully',
            singular: 'Advance delete successfully',
          },
        },
        unlink: {
          errorMessage: 'An error occurred while trying to detach advances',
          successMessage: {
            plural: 'Advances detached successfully',
            singular: 'Advance detached successfully',
          },
        },
        addButton: 'Add advance',
        grid: {
          searchFieldLabel: 'Search by advance code',
          statusLabel: 'Status',
          header: {
            columns: {
              advance: 'Code',
              advanceDate: 'Date',
              value: 'Value',
              status: 'Status',
              actions: 'Actions',
              requester: 'Requester',
            },
            batchActions: {
              delete: 'Delete',
              approve: 'Approve',
              reprove: 'Reprove',
              unlink: 'Unlink',
              link: 'Link',
              pay: 'Pay',
              sendToIntegration: 'Send to integration',
              tooltips: {
                status: 'Only reports with an "Pending" status can have this action performed',
              },
            },
          },
          body: {
            actions: {
              edit: 'Edit advance',
              pay: 'Pay',
              sendToIntegration: 'Send to integration',
              delete: 'Delete advance',
              link: 'Link advance',
              unlink: 'Unlink advance',
              view: 'View',
              approve: 'Approve',
              reprove: 'Reprove',
            },
            status: {
              pending: 'Pending',
              submitted: 'Submitted',
              open: 'Open',
              approved: 'Approved',
              reproved: 'Reproved',
              finished: 'Finished',
              awaiting_financial_processing: 'Awaiting financial processing',
              processing_payment: 'Waiting payment',
              processing_payment_error: 'Processing payment error',
            },
            occurrence: {
              plural: 'occurrences',
              singular: 'occurrence',
            },
          },
        },
        listPreview: {
          counterData: {
            label: 'Advances',
            items: {
              plural: '{{ items }} items',
              singular: '{{ items }} item',
            },
          },
        },
        modal: {
          exclusion: {
            iconDescription: 'Attention!',
            title: {
              plural: 'Are you sure you want to delete the selected advances?',
              singular: 'Are you sure you want to delete this advance?',
            },
            message: {
              plural: 'All data related to them will be lost and this action cannot be undone.',
              singular: 'All data related to it will be lost and this action cannot be undone.',
            },
            actions: {
              confirm: {
                plural: 'Confirm deletions',
                singular: 'Confirm deletion',
              },
            },
          },
          reprove: {
            iconDescription: 'Attention!',
            title: {
              plural: 'Are you sure you want to reprove the selected advances?',
              singular: 'Are you sure you want to reprove this advance?',
            },
            message: {
              plural:
                'The disapproved advance will be removed from the report and the employee can adjust and resubmit it for analysis, if necessary.',
              singular:
                'The disapproved advances will be removed from the report and the employee can adjust and resubmit them for analysis, if necessary.',
            },
            actions: {
              confirm: {
                plural: 'Confirm reprovals',
                singular: 'Confirm reproval',
              },
            },
          },
          unlink: {
            iconDescription: 'Attention!',
            title: {
              plural: 'Are you sure you want to unlink the selected advances?',
              singular: 'Are you sure you want to unlink this advance?',
            },
            message: {
              plural: 'The advances will go back into the listing and you will need to link them to a report again.',
              singular: 'The advance will go back into the listing and you will need to link it to a report again.',
            },
            actions: {
              confirm: {
                plural: 'Confirm unlinking',
                singular: 'Confirm unlinking',
              },
            },
          },
          approve: {
            iconDescription: 'Attention!',
            title: {
              plural: 'Are you sure you want to approve the selected advances?',
              singular: 'Are you sure you want to approve this advances?',
            },
            message: {
              plural: 'All data related to them will be lost and this action cannot be undone.',
              singular: 'All data related to it will be lost and this action cannot be undone.',
            },
            actions: {
              confirm: {
                plural: 'Confirm approvals',
                singular: 'Confirm approval',
              },
            },
          },
        },
      },
      importInfoContainer: {
        error: {
          row: 'Error on line {{line}}',
        },
      },
      BulkImportDetails: {
        title: 'Importing status',
        subTitle:
          'Follow the status of your import here. This process may take a few minutes, if you wish, you can close this page and continue using the platform normally and we will notify you when it is finished.',
        header: {
          clients: 'Add client by file',
          costCenters: 'Add costCenter by file',
        },
        newImportLabel: 'New import',
      },
      ImportInfoContainer: {
        errorProcessing: 'Processing ended with errors',
        peopleInfoError: {
          clients: {
            singular: '{{quantity}} client has an error in the registration, preventing import.',
            plural: '{{quantity}} clients have an error in the registration, preventing import.',
          },
          projects: {
            singular: '{{quantity}} project has an error in the registration, preventing import.',
            plural: '{{quantity}} projects have an error in the registration, preventing import.',
          },
          costCenters: {
            singular: '{{quantity}} cost center has an error in the registration, preventing import.',
            plural: '{{quantity}} cost centers have an error in the registration, preventing import.',
          },
        },
        peopleInfoSuccess: {
          clients: {
            singular: '{{quantity}} client has correct data for registration.',
            plural: '{{quantity}} clients have correct data for registration.',
          },
          projects: {
            singular: '{{quantity}} project has correct data for registrations.',
            plural: '{{quantity}} projects have correct data for registration.',
          },
          costCenters: {
            singular: '{{quantity}} cost center has correct data for registration.',
            plural: '{{quantity}} cost centers have correct data for registration.',
          },
        },
        errorDetails: 'See on the file below the errors. You should fix the errors and retry importing.',
        errorImporting: 'Some registers could not be imported.',
      },
      registerProject: {
        assignUsersGrid: {
          title: 'Select people',
          subTitle:
            'By default, all people will be able to post expenses to the customer, if you wish, select specific people.',
          header: {
            name: 'Name',
          },
          allPeople: 'All people ({{selected}})',
          selectedForProject: 'Selected to register expenses in this project ({{selected}})',
          feedback: {
            addUsers: {
              success: 'People assigned to project successfully',
              error: 'Error when assigning people to project',
            },
            removeUsers: {
              success: 'People successfully detached from the project',
              error: 'Error when unlinking people from the project',
            },
          },
        },
        bulkImport: {
          sendData: {
            title: 'Send data',
            subTitle:
              'Send customer data via spreadsheet or text file for batch registration. In the next steps you will be able to check everything and correct possible errors.',
          },
          fileModel: {
            warning: 'You must submit a file',
            title: 'Download the flash model and insert your data',
            subTitle: 'To avoid errors, it accepted only file in flash model format.',
          },
          sendFile: {
            title: 'Send your file',
            subTitle: 'With your file, now you just need to upload your file.',
          },
          help: {
            title: 'Need help with your file?',
            subTitle: 'In case of errors or help is needed, send your file to our support team.',
            actionButton: 'Send your file to support',
          },
          breadcrumbs: {
            manageCompany: 'Manage company',
            addByFile: 'Add project by file',
            projects: 'Project',
          },
          footer: {
            continue: 'Continue',
            cancel: 'Cancel',
          },
        },
      },
      clientsPreview: {
        title: 'Clients',
        subTitle: 'Manage the clients of your company that will appear in corporate expenses allocation.',
        addClient: {
          label: 'Add client',
          options: {
            addClientByFile: 'Add client by sheets',
            addClientManually: 'Add client by platform',
          },
        },
        breadcrumbs: {
          clients: 'Clients',
          manageCompany: 'Manage company',
        },
      },
      clientsGridPreview: {
        items: {
          plural: 'items',
          singular: 'item',
        },
        title: 'Clients registered',
        search: 'Search by client',
      },
      registerClient: {
        title: 'General data',
        subTitle: 'Insert the clients basic data.',
        assignUsersDescription:
          'As standard all people can register expenses to this client, if needed, select specific people to assign.',
        required: 'required field',
        breadcrumbs: {
          clients: 'Clients',
          manageCompany: 'Manage company',
          registerClient: 'Register client',
        },
        assignUsersGrid: {
          allPeople: 'All people',
          selectedForClient: 'Selected for this client',
          all: 'All ({{selected}})',
          selected: 'Selected ({{selected}})',
          header: {
            name: 'Name',
          },
        },
        steps: {
          header: {
            generalData: 'General data',
            users: 'Assign users',
          },
          pageContent: [
            {
              title: 'General Data',
              sectionTitle: 'General Data',
              subTitle: "Insert the client's basic data",
            },
            {
              title: 'Assign users',
              sectionTitle: 'Assign users',
              subTitle: 'Choose which people can register expenses to this project.',
            },
          ],
        },
        bulkImport: {
          sendData: {
            title: 'Send data',
            subTitle: {
              clients:
                'Send customers data via spreadsheet or text file for batch registration. In the next steps you will be able to check everything and correct possible errors.',
              projects:
                'Send projects data via spreadsheet or text file for batch registration. In the next steps you will be able to check everything and correct possible errors.',
              costCenters:
                'Send cost centers data via spreadsheet or text file for batch registration. In the next steps you will be able to check everything and correct possible errors.',
            },
          },
          fileModel: {
            warning: 'You must submit a file',
            title: 'Download the flash model and insert your data',
            subTitle: 'To avoid errors, it accepted only file in flash model format.',
          },
          sendFile: {
            title: 'Send your file',
            subTitle: 'With your file, now you just need to upload your file.',
          },
          help: {
            title: 'Need help with your file?',
            subTitle: 'In case of errors or help is needed, send your file to our support team.',
            actionButton: 'Send your file to support',
          },
          breadcrumbs: {
            manageCompany: 'Manage company',
            addByFile: 'Add by file',
            clients: 'Clients',
          },
          footer: {
            continue: 'Continue',
            cancel: 'Cancel',
          },
        },
      },
      registerClientForm: {
        name: "Client's name",
        phone: "Client's Phone",
        email: "Client's E-mail",
        site: "Client's Site",
        referenceId: 'Code',
        referenceIdHelper: 'Insert the code used by your company for better management.',
        active: 'Active client',
        activeDescription: 'When active, the client shows as an option when registering an expense.',
        error: {
          fieldRequired: '{{field}} field must be filled',
        },
      },
      clientsGrid: {
        client: 'Client',
        phone: 'Phone',
        status: 'Status',
        actions: 'Actions',
        uninformedEmailClient: 'E-mail not informed',
        active: {
          true: 'Active',
          false: 'Inactive',
        },
        actionsGrid: {
          singularForm: {
            edit: 'Edit',
            delete: 'Delete',
            activate: 'Activate',
            deactivate: 'Deactivate',
          },
          pluralForm: {
            delete: 'Delete',
            activate: '',
            deactivate: '',
          },
        },
        selectedItems: '{{selectedItems}} out of {{totalItems}} selected',
      },
      analyticsPreview: {
        tabLabel: {
          expense: 'Expense',
          insights: 'Insights',
          reports: 'Reports',
        },
      },
      setupCategory: {
        title: 'Configure as categorias',
        description:
          'As categorias são utilizadas na prestação de contas para especificar o tipo de despesa, facilitando a análise dos gastos da sua corporação. Ative as categorias que deseja disponibilizar na plataforma.',
        createButton: 'Criar categoria',
        formTitleCreate: 'Criar categoria',
        formTitleUpdate: 'Editar categoria',
        disableAll: 'Desativar todas',
        enableAll: 'Ativar todas',
      },
      setupLimits: {
        title: 'Configure os limites',
        description:
          'Os limites de gastos por categoria permitem que o sistema identifique automaticamente quando uma despesa viola à regra, facilitando a análise das prestações de contas.',
      },
      expenseGrid: {
        category: 'Category',
        date: 'Date',
        value: 'Value',
        place: 'Place',
        receipt: 'Receipt',
        actions: 'Actions',
        expenses: 'Expenses',
        pendingExpenses: 'Pending expenses',
        ascendingOrder: 'Ascending order',
        descendingOrder: 'Descending order',
        items: 'items',
        uninformedCategory: 'Not Categorized',
        establishment: 'Place',
        expenseDate: 'Receipt',
        attachments: 'Date',
        occurrence: 'occurrence',
        occurrences: 'occurrences',
        referenceId: 'ID',
        actionsGrid: {
          feedbacks: {
            unlink: {
              pluralForm: {
                error: 'An error occurred while unlinking expenses!',
                success: 'Expenses successfully unlinked!',
              },
              singularForm: {
                error: 'An error occurred while unlinking the expense!',
                success: 'Expense successfully unlinked!',
              },
            },
            delete: {
              pluralForm: {
                error: 'An error occurred while deleting expenses!',
                success: 'Expenses deleted successfully!',
              },
              singularForm: {
                error: 'An error occurred while deleting the expense!',
                success: 'Expense deleted successfully!',
              },
            },
          },
          pluralForm: {
            unlink: 'Unlink expenses',
            delete: 'Delete expenses',
            remove: 'Remove expenses',
          },
          singularForm: {
            view: 'See expense',
            edit: 'Edit expense',
            unlink: 'Unlink expense',
            delete: 'Delete expense',
          },
          tooltip: {
            delete:
              'Excluding expenses is only allowed for expenses that are unlinked or linked to a report with an "Open" status.',
            emptyReceipts: 'Expense without linked receipts.',
          },
        },
        selectedItems: '{{selectedItems}} out of {{totalItems}} selected',
      },
      expenseReceipts: {
        loading: 'Loading receipts... ',
      },
      expenseActions: {
        expense: 'Expense',
        mileage: 'Mileage',
        import: 'Import',
      },
      reportGrid: {
        name: 'Report name',
        activePeriod: 'Period',
        value: 'Value',
        status: 'Status',
        id: 'Id',
        actions: 'Actions',
        expenses: 'Expenses',
        viewReport: 'View report',
        editReport: 'Edit report',
        deleteReport: 'Delete report',
        ascendingOrder: 'Ascending order',
        descendingOrder: 'Descending order',
        selectedItems: '{{selectedItems}} out of {{totalItems}} selected',
        search: 'Search by report name, id or requester name',
      },
      expenseRegisterForm: {
        ocr: {
          error: {
            title: 'Error on automatic reading',
          },
          loading: 'Getting receipt data...',
        },
        fieldLabels: {
          client: 'Client',
          project: 'Project',
          quantity: 'Quantity',
          category: 'Category',
          address: 'Establishment',
          receiptFile: 'Receipt file',
          currency: 'Currency',
          paymentType: 'Payment method',
          value: 'value',
          date: 'Expense date',
          description: 'Comment',
          costCenter: 'Cost center',
          refundable: 'Refundable',
          billable: 'Billable',
          report: 'Report',
          invoice: 'Invoice',
          invoiceKey: 'Invoice key',
          odometer: {
            start: 'Odometer start km',
            end: 'Odometer end km',
          },
        },
        optionsNotFound: 'Opções não encontradas',
        mandatoryField: 'Mandatory field',
      },
      expenseHistoryGrid: {
        by: 'by',
        date: 'Date',
        actions: 'Actions',
        title: 'History',
      },
      odometerExpenseDetails: {
        initialKm: 'Odometer initial km',
        finalKm: 'Odometer final km',
        distance: 'Distance',
        notInformed: 'Not informed',
      },
      approverReportGrid: {
        actionsOptions: {
          tooltip: {
            approval: 'Only reports with an "Submitted" status can be approved',
            reprove: 'Only reports with an "Submitted" status can be disapproved',
          },
        },
      },
      batchActionsGrid: {
        selectedItems: '{{selectedItems}} out of {{totalItems}} selected',
      },
      accountingPending: {
        statusCard: {
          title: 'expenses',
          pendingAccounting: 'Payment of outstanding accounts',
          outOfPolitics: 'Out of politics',
          corporateCardExpenses: 'Corporate card expense',
          filter: 'Check expenses',
          clearFilter: 'Clear filter',
          filterButton: {
            addFilter: 'Analyze expenses',
            removeFilter: 'Remove filter',
          },
        },
        expensesTable: {
          header: {
            name: 'Name',
            expensePendingAmount: 'Value',
            reportsCount: 'Report',
            paymentMethods: 'Report content',
            action: 'Actions',
          },
          body: {
            reportsCount: 'Reports',
            totalReport: 'Report',
            totalPendingExpense: 'Expense',
            pendingExpensesCount: 'Expenses',
            violationCount: '{{violationCount}} {{expense}} out of politics',
            totalReceiptNotFound: 'Missing {{totalReceiptNotFound}} receipts',
          },
          subRows: {
            noReportsFound: "There's no report",
            loadingSubRows: 'Loading...',
            loadMore: 'Load more',
            paginationOf: 'of',
          },
          action: {
            seeReport: 'See report',
            seeExpense: 'See expense',
            seeReceipts: 'See receipts',
            request: 'Request installment',
          },
          filters: {
            userName: 'Team member',
            paymentMethod: 'Report content',
            creationDate: 'Creation date',
            value: 'Value',
          },
        },
      },
      financialReportGrid: {
        report: 'Report',
        requester: 'requester',
        name: 'Report name',
        value: 'Value',
        status: 'Status',
        period: 'Period',
        date: 'Date',
        actions: 'Actions',
        selectedItems: '{{selectedItems}} out of {{totalItems}} selected',
        orderBy: {
          ascendingOrder: 'Ascending order',
          descendingOrder: 'Descending order',
        },
        statusOptions: {
          open: 'Open',
          reimbursed: 'Reimbursed',
          submitted: 'Submitted',
          reproved: 'Reproved',
          approved: 'Approved',
          pending: 'Pending',
          awaiting_financial_processing: 'Awaiting financial processing',
          processing_payment: 'Awaiting payment',
          processing_payment_error: 'Payment error',
        },
        actionsOptions: {
          repay: 'Repay',
          sendIntegration: 'Send to integration',
          integration: 'To integrate',
          cancel: 'Cancel',
          cancelIntegration: 'Cancel integration',
          reprove: 'Reprove',
          exportPdf: 'Export PDF',
          exportPdfByCategory: 'Export PDF by category',
          view: 'See Report',
          tooltip: {
            repay: 'Only reports with an "Approved" status can be refunded',
            integration: 'Only reports with an "Approved" status can be sent to integration',
            cancelIntegration:
              'Only reports with the status "Awaiting financial processing" and "Payment error" can cancel the integration.',
            reprove: 'Only reports with an "Approved" status can be disapproved',
            exportPdf: '',
            exportPdfByCategory: '',
            exportExcel: 'To carry out the export to excel it is necessary to filter a period',
          },
          feedbacks: {
            export: {
              pluralForm: {
                error: 'An error occurred while exporting reports',
                processing: 'Starting the download of PDF reports',
              },
              singularForm: {
                error: 'An error occurred while exporting report',
                processing: 'Starting to download the PDF report',
              },
              notFound: 'No reports found',
            },
          },
        },
      },
      justificationModalReportReproval: {
        attention: 'Attention!',
        enterTheJustification: 'Enter the justification',
        pluralForm: {
          justifyTheDisapproval: 'Justify the disapprovals',
          popupTitle: 'Are you sure you want to disapprove the {{reportsSelected}} selected reports?',
          popupSubtitle:
            'After being disapproved, the collaborator will be able to reopen them and make the changes to submit them again.',
          confirmDisapproval: 'Confirm failures',
        },
        singularForm: {
          justifyTheDisapproval: 'Justify the disapproval',
          popupTitle: 'Are you sure you want to disapprove the report?',
          popupSubtitle:
            'After being disapproved, the collaborator will be able to reopen it and make the changes to submit it again.',
          confirmDisapproval: 'Confirm disapproval',
        },
      },
      justificationModalReportCancelIntegration: {
        attention: 'Attention!',
        pluralForm: {
          popupTitle: 'Are you sure you want to cancel the integration of {{reportsSelected}} selected reports?',
          popupSubtitle:
            'After cancellation, the reports will return to approved status and will continue to be available for financial actions.',
          confirmDisapproval: 'Confirm',
        },
        singularForm: {
          popupTitle: 'Are you sure you want to cancel report integration?',
          popupSubtitle:
            'After cancellation, the report will return to approved status and will continue to be available for financial actions.',
          confirmDisapproval: 'Confirm',
        },
      },
      justificationModalAdvanceReproval: {
        attention: 'Attention!',
        enterTheJustification: 'Enter the justification',
        pluralForm: {
          justifyTheDisapproval: 'Justify the disapprovals',
          popupTitle: 'Are you sure you want to disapprove the {{advancesSelected}} selected advances?',
          popupSubtitle:
            'After being disapproved, the collaborator will be able to reopen them and make the changes to submit them again.',
          confirmDisapproval: 'Confirm failures',
        },
        singularForm: {
          justifyTheDisapproval: 'Justify the disapproval',
          popupTitle: 'Are you sure you want to disapprove the advance?',
          popupSubtitle:
            'After being disapproved, the collaborator will be able to reopen it and make the changes to submit it again.',
          confirmDisapproval: 'Confirm disapproval',
        },
      },
      justificationModalRemoveExpense: {
        attention: 'Attention!',
        enterTheJustification: 'Enter the reason',
        pluralForm: {
          justifyTheDisapproval: 'Justify the removals',
          popupTitle: 'Are you sure you want to disapprove the selected {{expensesSelected}} expenses?',
          popupSubtitle:
            'Disapproved expenses will be removed from the report and the contributor will be able to adjust them and resubmit them for review if necessary.',
          confirmDisapproval: 'Confirm disapprovals',
        },
        singularForm: {
          justifyTheDisapproval: 'Justify the removal',
          popupTitle: 'Are you sure you want to disapprove the report expense?',
          popupSubtitle:
            'The disapproved expense will be removed from the report and the employee will be able to adjust it and resubmit it for review, if necessary.',
          confirmDisapproval: 'Confirm disapproval',
        },
      },
      confirmModalReportRepay: {
        attention: 'Attention!',
        conclude: 'Conclude',
        refund: {
          pluralForm: {
            popupTitle: 'Are you sure you want to refund the {{reportsSelected}} selected reports?',
          },
          singularForm: {
            popupTitle: 'Are you sure you want to refund the report?',
          },
        },
        integration: {
          pluralForm: {
            popupTitle: 'Are you sure you want to send the {{reportsSelected}} selected reports to integration?',
          },
          singularForm: {
            popupTitle: 'Are you sure you want to send the report to integration?',
          },
        },
        singularForm: {
          popupSubtitle: 'The report will be closed and cannot be edited again.',
        },
        pluralForm: {
          popupSubtitle: 'Reports will be closed and cannot be edited again.',
        },
      },
      expirationDateModal: {
        conclude: 'Conclude',
        popupTitle: 'Expiration date',
        requiredField: 'MandatoryField',
        expirationDateValidation: 'Date selected must be equal or greater than today.',
        pluralForm: {
          popupSubtitle: 'Reports will be closed and cannot be edited again.',
        },
        singularForm: {
          popupSubtitle: 'The report will be closed and cannot be edited again.',
        },
      },
      establishments: {
        fieldLabel: 'Establishment',
        empty: {
          title: 'Search locations',
          caption: 'Find a place by name or address by entering the street, number, neighborhood or city.',
        },
        fixedMessage: {
          title: 'Didnt find the location?',
          caption: 'Enter more details such as number, district or city.',
        },
        loader: {
          description: 'Loading options...',
        },
        loading: 'loading...',
      },
      confirmationModalUnlinkExpense: {
        attention: 'Attention!',
        pluralForm: {
          popupTitle: 'Are you sure you want to unlink {{expensesSelected}} expenses from the report?',
          popupSubtitle: 'Expenses will move back into the listing, and you will need to link them to a report again',
          confirm: 'Confirm unlinks',
        },
        singularForm: {
          popupTitle: 'Are you sure you want to unlink the expense from the report?',
          popupSubtitle: 'The expense will go back into the listing and you will need to link it to a report again.',
          confirm: 'Confirm Unlinking',
        },
      },
      confirmationModalDeleteExpense: {
        attention: 'Attention!',
        pluralForm: {
          popupTitle: 'Are you sure you want to delete the {{expensesSelected}} selected expenses?',
          popupSubtitle: 'All data related to it will be lost and this action cannot be undone.',
          confirm: 'Confirm deletions',
        },
        singularForm: {
          popupTitle: 'Are you sure you want to delete the expense?',
          popupSubtitle: 'All data related to it will be lost and this action cannot be undone.',
          confirm: 'Confirm deletion',
        },
      },
      expenseDetails: {
        occurrences: {
          title: 'This expense has',
          pluralForm: {
            occurrences: 'occurrences',
          },
          singularForm: {
            occurrences: 'occurrence',
          },
        },
        expenseTitle: {
          originDestination: 'Mileage - Origin and Destination',
          odometer: 'Mileage - Odometer',
        },
        route: 'Way',
      },
      reportDetailsPreview: {
        feedback: {
          sendReport: {
            singularForm: {
              success: 'Report sent successfully!',
            },
            pluralForm: {
              success: 'Reports sent successfully!',
            },
            confirmation: {
              attention: 'Atenção',
              title: 'Report split',
              proceed: 'Continue',
            },
            feedback: {
              success: 'Success',
              title: 'Reports submitted successfully!',
              message:
                'Expenses have been added to {{referenceId}} reports, which have been successfully submitted for approval.',
              goToReports: 'Go to reports',
            },
          },
          reopenReport: {
            singularForm: {
              success: 'Report reopened successfully!',
              modalTitle: 'Are you sure you want to reopen the report?',
              modalMsg: 'The report will reopen so you can edit it and resubmit it for review and approval.',
            },
            pluralForm: {
              success: 'Reports reopened successfully!',
              modalTitle: 'Are you sure you want to reopen the selected reports?',
              modalMsg: 'The reports will reopen so you can edit and resubmit them for review and approval.',
            },
          },
        },
        occurrences: {
          singularForm: {
            title: 'This report has {{occurrencesNumber}} occurrence:',
          },
          pluralForm: {
            title: 'This report has {{occurrencesNumber}} occurrences:',
          },
        },
      },
      financialReportsPreview: {
        pluralForm: {
          refundedSuccessMessage: 'Reports successfully refunded!',
          disapprovedSuccessMessage: 'Reports failed Successfully!',
          integrationSuccessMessage: 'Reports sent for integration.',
          cancelIntegrationSuccessMessage: 'Cancellations made.',
        },
        singularForm: {
          refundedSuccessMessage: 'Report successfully refunded!',
          disapprovedSuccessMessage: 'Report failed successfully!',
          integrationSuccessMessage: 'Report sent for integration.',
          cancelIntegrationSuccessMessage: 'Cancellation made.',
        },
      },
      mileageRatesPreview: {
        feedbacks: {
          mileageRateDeletedSuccessfully: 'Mileage rate deleted successfully',
          errorDeletingMileageRate: 'An error occurred while deleting the mileage rate',
          mileageRateSavedSuccessfully: 'Mileage rate saved successfully',
          errorSavingMileageRate: 'An error occurred while saving the mileage rate',
        },
      },
      attachExpenseModal: {
        title: 'Attach expense to report',
        subtitle: 'Select the expenses that you want to attach to the report.',
        add: 'Add',
        emptyExpenses: 'No expenses available for linking!',
      },
      attachAdvancesModal: {
        title: 'Attach advance to report',
        subtitle: 'Select the advances that you want to attach to the report.',
        add: 'Add',
        emptyExpenses: 'No advances available for linking!',
      },
      customizationsPreview: {
        tabTitle: {
          expense: 'Corporate expenses',
          companyPayables: 'Vendor expenses',
          corporateTravel: 'Corporate Travel',
        },
        tabLabel: {
          categories: 'Categories',
          expenses: 'Expenses',
          policies: 'Policies',
          approvals: 'Aprovações',
          reports: 'Reports',
          generalSettings: 'General settings',
          forms: 'Forms',
          advances: 'Advances',
          delegation: 'Delegation of actions',
        },
      },
      customizationsPaymentMethods: {
        title: 'Payment methods',
        caption: 'Add payment methods that can be selected when posting expenses.',
        searchFieldLabel: 'Search by payment method',
        addPaymentMethod: {
          buttonAdd: 'Add payment method',
        },
        grid: {
          header: {
            code: 'Code',
            paymentMethod: 'Payment method',
            status: 'Status',
            mileage: 'Mileage',
            refundable: 'Refundable',
            actions: 'Actions',
            tooltips: {
              mileage: 'Indicates whether the payment method can be used for mileage expenses.',
              refundable: 'Indicates whether the payment method allows the expense to be reimbursed.',
            },
            batchActions: {
              deactivate: 'Deactivate',
              activate: 'Activate',
              delete: 'Delete',
              tooltips: {
                delete: 'Default payment methods cannot be deleted',
                active: 'Select active payment methods',
              },
            },
          },
          body: {
            active: 'Active',
            deactivate: 'Deactivate',
            yes: 'Yes',
            not: 'No',
            actions: {
              edit: 'Edit payment method',
              deactivate: 'Deactivate payment method',
              active: 'Activate payment method',
              delete: 'Delete payment method',
            },
          },
        },
        modal: {
          paymentMethod: {
            title: {
              add: 'Add payment method',
              edit: 'Edit payment method',
            },
            fields: {
              code: {
                label: 'Code',
                helperText:
                  'Enter the code used by your company for better control. The use of this code facilitates the reconciliation of reports exported by our platform',
              },
              paymentMethodName: 'Payment method name',
              settings: {
                title: 'Settings',
                label: 'Activate payment method',
                caption: 'When enabled, the payment method appears as an option when posting an expense.',
              },
              refundablePaymentMethod: {
                label: 'Refundable payment method',
                tooltip: 'Allows the possibility of reimbursement for expenses registered with this form of payment.',
              },
              allowUseOnMileageExpenses: 'Allow use on mileage expenses',
              tooltip: {
                codeFiled: 'The code field cannot be edited in the default payment method!',
                paymentMethodNameField: 'The payment method name field cannot be edited in the default payment method!',
              },
              validationMessage: 'Fill in all fields!',
            },
            actionButton: {
              add: 'Add',
              edit: 'Save',
            },
            feedbacks: {
              success: 'Change performed successfully!',
              error: 'Unable to make requested change!',
              add: {
                success: 'Payment method successfully added!',
                error: 'Unable to add payment method. Please try again.',
              },
              edit: {
                success: 'Payment method changed successfully!',
                error: 'Unable to change payment method. Please try again.',
              },
              active: {
                success: 'Activation status change successfully performed!',
                error: 'Error when changing activation status!',
              },
              delete: {
                error: {
                  title: 'There was an error deleting the payment method: {{methodName}}!',
                  message: 'Could not delete the requested payment method!',
                },
                success: 'Payment method deleted successfully!',
              },
              refundable: {
                confirmationMessage:
                  'Changing the refundable flag will be replicated for all pending expenses. Do you wish to continue?',
              },
            },
          },
          exclusion: {
            iconDescription: 'Attention!',
            title: {
              plural: 'Are you sure you want to delete the selected payment methods?',
              singular: 'Are you sure you want to delete this payment method?',
            },
            message: {
              plural: 'All data related to them will be lost and this action cannot be undone.',
              singular: 'All data related to it will be lost and this action cannot be undone.',
            },
            actions: {
              confirm: {
                plural: 'Confirm deletions',
                singular: 'Confirm deletion',
              },
            },
          },
          deactivation: {
            iconDescription: 'Attention!',
            title: {
              plural: 'Are you sure you want to disable the selected payment methods?',
              singular: 'Are you sure you want to disable this payment method?',
            },
            message: {
              plural:
                'By deactivating them, they will no longer appear in the cost center listing when posting expenses.',
              singular:
                'By deactivating it, it will no longer appear in the cost center listing when posting expenses.',
            },
            actions: {
              confirm: {
                plural: 'Confirm deactivations',
                singular: 'Confirm deactivation',
              },
            },
          },
          refundable: {
            iconDescription: 'Attention!',
            title: {
              singular: 'Are you sure you want to make this change?',
            },
            actions: {
              confirm: {
                singular: 'Confirm',
              },
            },
          },
        },
      },
      customizationsCustomFields: {
        title: 'Customizable fields',
        caption: 'Add customizable fields that can be filled in when posting expenses.',
        searchFieldLabel: 'Search by customizable field',
        addCustomField: {
          buttonAdd: 'Add customizable field',
          disabledTooltip: 'You can only create up to 10 custom fields',
        },
        grid: {
          header: {
            customField: 'Field',
            status: 'Status',
            required: 'Required',
            type: 'Type',
            actions: 'Actions',
            batchActions: {
              deactivate: 'Deactivate',
              activate: 'Activate',
              delete: 'Delete',
            },
          },
          body: {
            active: 'Active',
            deactivate: 'Inactive',
            yes: 'Yes',
            not: 'No',
            actions: {
              edit: 'Edit custom field',
              active: 'Activate custom field',
              deactivate: 'Deactivate custom field',
              delete: 'Delete custom field',
              moveUp: 'Move up',
              moveDown: 'Move down',
            },
          },
        },
        modal: {
          customField: {
            title: {
              add: 'Add custom field',
              edit: 'Edit custom field',
            },
            preview: {
              description: 'Check the field preview',
            },
            configureField: 'Configure field',
            addFieldInfo: 'Configure field information',
            fields: {
              customFieldName: 'Custom field name',
              customFieldType: {
                label: 'Custom field type',
                options: {
                  TEXT: 'Text',
                  DATE: 'Date',
                  NUMBER: 'Numeric',
                  OPTIONS: 'Options',
                  BAR_CODE: 'Bar code reader',
                },
              },
              options: {
                addOptions: 'Add options',
                addOption: 'Add option',
                option: 'Option',
              },
              addSelectOptions: {
                label: 'Add items to selection',
              },
              allowEditByField: {
                label: 'Allow editing by',
                tooltip: 'Select the type of user who can change the field',
                options: {
                  ADMIN: 'Administrator',
                  APPROVER: 'Approver',
                  USER: 'User',
                  FINANCIAL: 'Financial',
                },
              },
              settings: {
                title: 'Settings',
                label: 'Activate custom field',
                caption: 'When active, the custom field appears as a field to fill in when posting an expense.',
              },
              requiredField: {
                label: 'Required field',
                tooltip: 'When mandatory, the user can only post the expense if the field is filled in.',
              },
              validationMessage: 'Fill in all fields!',
            },
            actionButton: {
              add: 'Add',
              edit: 'Save',
            },
            feedbacks: {
              success: 'Change made successfully!',
              error: 'Unable to make changes!',
              add: {
                success: 'Customized field added successfully!',
                error: 'Unable to add custom field. Please try again.',
              },
              edit: {
                success: 'Customized field changed successfully!',
                error: 'Unable to change custom field. Please try again.',
              },
              active: {
                success: 'Activation status change successful!',
                error: 'Error changing activation status!',
              },
              delete: {
                error: {
                  title: 'An error occurred while deleting custom field: {{methodName}}!',
                  message: 'Unable to delete the requested custom field!',
                },
                success: 'Customized field deleted successfully!',
              },
            },
          },
          exclusion: {
            iconDescription: 'Attention!',
            title: {
              plural: 'Are you sure you want to delete the selected custom fields?',
              singular: 'Are you sure you want to delete this custom field?',
            },
            message: {
              plural: 'All data related to them will be lost and this action cannot be undone.',
              singular: 'All data related to it will be lost and this action cannot be undone.',
            },
            actions: {
              confirm: {
                plural: 'Confirm deletions',
                singular: 'Confirm deletion',
              },
            },
          },
          deactivation: {
            iconDescription: 'Attention!',
            title: {
              plural: 'Are you sure you want to disable the selected custom fields?',
              singular: 'Are you sure you want to disable this custom field?',
            },
            message: {
              plural:
                'When you deactivate them, they will no longer appear in the cost center listing when posting expenses.',
              singular:
                'When you deactivate it, it will no longer appear in the cost center listing when posting expenses.',
            },
            actions: {
              confirm: {
                plural: 'Confirm deactivations',
                singular: 'Confirm deactivation',
              },
            },
          },
        },
      },
      generalReportSettings: {
        title: 'General settings',
        caption: 'Set basics information for expense registering.',
        allowReimbursementToContainReimbursementExpenseOnly: {
          allow: 'Allow reimbursement reports to only contain reimbursement expenses',
          allowCostCenter: 'Cost center',
          allowPaymentMethod: 'Form of payment',
          allowProject: 'Project',
          allowCategory: 'Category',
          allowByExpense: 'By expense',
          subsidiary: 'Subsidiary',
          department: 'Department',
          costCenterSelectLabel: 'Select cost center',
          costCenterTooltip:
            'When the separation is done by cost center, it must be possible to choose whether the branch or area cost center will be used. It is worth mentioning that this definition is not mandatory',
        },
        allowSameUserToApproveAdvanceAndReport: 'Allow the same user to approve advance and report',
        expirationDate: {
          allowAdd: 'Add due date to report',
          conditionalType: '',
          expirationCriteria: 'Type criterion',
          typeOfFrequency: '',
          value: '',
          configureAutomaticExpiryData: 'Configure automatic expiry data',
          manual: 'Manual',
          automatic: 'Automatic',
          configurationModal: {
            title: 'Configure automatic expiration date',
            caption: 'Select one of the options to define the automatic expiration rule for reports.',
            actionButton: 'To save',
            cancelButton: 'Cancel',
            fields: {
              after: 'after',
              reportsExpireEvery: 'Reports expire every',
              frequency: 'Frequency',
              reportsExpireAll: 'Reports expire all',
              weeklyFrequency: 'Weekly frequency',
              reportsEreDueOnTheDay: 'Reports are due on the day',
              expirationDateType: 'Type',
              manual: 'Manual',
              automatic: 'Automatic',
              days: 'Days',
              dayOfTheWeek: 'Weeks',
              daysOfMonth: 'Months',
              whenReimbursed: 'Report be refunded',
              whenSentForIntegration: 'Report be sent for integration',
              whenApproved: 'Report be approved',
              daysAfter: 'day(s) after',
              theNext: 'the next',
              everyDay: 'every day',
              ofTheMonthAfter: 'of the month after',
              monday: 'Monday',
              tuesday: 'Tuesday',
              wednesday: 'Wednesday',
              thursday: 'Thursday',
              friday: 'Friday',
            },
            tooltip: {
              invalidFields: 'Fill in the fields of the chosen option!',
            },
          },
        },
        prefixValue: 'Prefix',
        startValue: 'Initial number',
        codeTitle: 'Code',
        codeCaption: 'Define a code that will facilitate the reconciliation of reports exported by the platform.',
        tooltip: {
          addDateToggle:
            'Date the user will receive the refund or the report will be accounted for. If not selected, the report has an open status until finalized by the financial',
          allowReportsOfTheSameToggle:
            'When enabled, the system will organize the reports to have expenses of the same criteria. Expenses that do not follow the criteria will be allocated in another report.',
        },
      },
      exportPreferencesReports: {
        title: 'Export preferences',
        includeInExportedDocument: 'Include in exported document:',
        pdfExportSettings: {
          includeCopyOfReceiptsAttached: 'Copy of receipts attached to the report',
          includeCostCenterSubtotal: 'Subtotal by cost center',
          includeHistory: 'Report history',
          includeMileageSubtotal: 'SubTotal by mileage',
          includeProjectSubtotal: 'Subtotal per project',
          includeRequestorData: 'Applicant data',
          includeSignature: 'Subscriptions',
          includeViolation: 'Policy violation',
        },
      },
      categoriesPreview: {
        title: 'Categories',
        description: 'Define the expense categories that you want to be available for posting expenses.',
        createCategory: 'Create category',
        disableAll: 'Disable all',
        enableAll: 'Enable All',
        create: 'Create',
        deleteModal: {
          headerIconLabel: 'Heads up!',
          headerTitle: 'Are you sure you want to delete this category?',
          headerSubtitle: 'All data related to it will be lost and this action cannot be undone.',
          confirmDeletion: 'Confirm deletion',
        },
      },
      categoryFormModal: {
        fieldsLabel: {
          code: 'Company Code',
          description: 'Category name',
          activated: 'activated',
          contabilityAccountName: 'Contability account name',
          contabilityAccountCode: 'Contability account code',
        },
        createCategory: 'Create category',
        editCategory: 'Edit category',
        helperTextCodeField: 'Enter the code used by your company for better control.',
        mandatoryDescription: 'Required field',
        create: 'Create',
        edit: 'Edit',
        feedback: {
          create: {
            success: 'Category created successfully!',
            error: 'An error occurred while creating the category.',
          },
          update: {
            success: 'Category updated successfully!',
            error: 'An error occurred while updating the category..',
          },
        },
      },
      limitGrid: {
        category: 'Category',
        criterion: 'Criterion',
        limit: 'Limit',
        notAllowedActions: 'Not allowed actions',
        limitBlocksSending: 'Limit blocks sending',
        proofRequired: 'Invoice required',
        commentRequired: 'Comment required',
        orderBy: {
          ascendingOrder: 'Ascending order',
          descendingOrder: 'Descending order',
        },
        actions: 'Actions',
        actionsOptions: {
          edit: 'Edit limit',
          disable: 'Disable limit',
          enable: 'Enable limit',
          delete: 'Delete limit',
        },
        true: 'Yes',
        false: 'No',
        criterionOptions: {
          DAY: 'By day',
          EXPENSE: 'By expense',
          QUANTITY: 'By quantity',
          MONTH: 'By month',
        },
        status: 'Status',
        active: {
          true: 'Active',
          false: 'Inactive',
        },
        selectedItems: '{{selectedItems}} out of {{totalItems}} selected',
      },
      policiesPreview: {
        title: 'Policies',
      },
      limitsPreview: {
        add: 'Add limit',
        accordionTitle: 'Expense limits',
        accordionDescription: 'Set limits for expenses in expenses categories.',
      },
      approvalsFluxPreview: {
        accordionTitle: 'Approval Flow',
        accordionDescription: 'Configure how the approval flow for reports will work.',
        approvalModes: {
          manual: {
            title: 'Manual approval',
            description:
              'The requester selects the person responsible for approving their report from among those with the approver profile.',
          },
          automatic: {
            title: 'Auto Approval',
            description:
              'When submitting the report, it is automatically sent to finance, without the need for approval by an approver.',
          },
          custom: {
            title: 'Custom Approval',
            description: 'Create a custom flow according to your company needs.',
            actions: {
              reportApproval: 'Report Approval',
              advanceApproval: 'Advance Approval',
            },
          },
          tooltip: {
            actionNotAllowed: 'You are not allowed to change the approval flow!',
          },
        },
        feedbacks: {
          errorWhileUpdatingType: 'Error occurs while trying to update approval type',
        },
      },
      mileagePreview: {
        title: 'Mileage',
        accordionTitle: 'General settings',
        accordionDescription: 'Edit the basic settings that affect the posting of mileage expenses',
        mileageToggles: {
          originDestination: {
            title: 'Origin-destination',
            description:
              'Allows you to enter mileage expenses by entering the starting point and arrival point, as well as any stops.',
          },
          odometer: {
            title: 'Odometer',
            description:
              "Allows the entry of mileage expenses by entering the vehicle's KM at the beginning of the route, as well as at the end, thus resulting in the distance traveled.",
          },
          gpsPin: {
            title: 'GPS',
            description: 'Allows the entry of expenses through tracing the route by GPS.',
            appOnly: 'App only',
          },
          gps: {
            title: 'GPS Pin',
            description:
              "Allows the insertion of the user's location via GPS when entering the expense (starting, stopping or arrival point).",
          },
          registeredPlaces: {
            title: 'Registered locations',
            description: 'Allows the selection of locations that were registered in the posting of expenses.',
          },
          multipleAddresses: {
            title: 'Multiple addresses (stops)',
            description: 'Allows the entry of multiple addresses (stopping points) when posting mileage expenses.',
          },
          manualEntry: {
            title: 'Manual input',
            description: 'Allows manual entry of addresses.',
          },
        },
        distanceUnit: {
          title: 'Distance unit',
          kilometer: 'Kilometer',
          miles: 'Miles',
        },
        mileageRates: {
          accordionTitle: 'Mileage Rate',
          accordionDescription: 'Set the value per kilometer driven.',
          addKmRate: 'Add mileage rate',
          startDate: 'Start date',
          ratePerKm: 'Rate per kilometer',
        },
      },
      subscriptionDetails: {
        title: 'Subscription details',
        valueCaption: 'person/month',
        plans: {
          basic: 'Expense Management',
          intermediate: 'Intermediate',
          advanced: 'Complete Expense Management',
        },
        travel: {
          title: 'Travel services',
        },
        sass: {
          title: 'Expense management software ("SaaS")',
          description: {
            basic: 'The {{planName}} plan is free, with no charge for the services provided.',
            intermediate:
              'Check the details of the subscription to the intermediate plan and proceed with hiring our services.',
            advanced:
              'Check the details of the subscription to the Complete Expense Management plan and proceed with hiring our services.',
          },
          caption: '{{planName}} Plan Version',
        },
        corporateCard: {
          title: 'Corporate Flash Card',
          caption: '{{planName}} Plan Version',
          infos:
            '* For customers who do not purchase benefits, <b>{{freeCardQuantity}} cards</b> will be made available free of charge and a one-time fee of <b>{{additionalValue}}</b> will be charged for each additional corporate card requested.',
        },
        erpIntegration: {
          title: 'ERP Integration',
          caption: 'Additional service',
        },
        details: {
          title: 'Total subscription amount',
          caption: {
            periodOfValidity: 'Term – 12 months, without termination penalty',
            gracePeriodInMonths: 'Grace period (In months): {{period}} months',
            discountPeriodInMonths: 'Discount period (In months): {{period}} months',
            discountPercentage: 'Discount applied (In %): {{discount}}%',
            billingTypeDescription:
              'The minimum number of lives/users, as well as the value of the package, were closed to the economic group. Therefore, they will be calculated for the group as a whole, adding up the active users in each CNPJ.',
            billingTypeCompany: 'Billing will be centralized at the head office (CNPJ: {{registrationNumber}}).',
          },
        },
      },
      customizationTipsModal: [
        {
          labelButtonAction: 'Show me how to navigate',
          labelButtonCancel: 'No, thanks',
          title: 'Starting is easier when you have a map showing how to navigate.',
          iconLabel: "Let's explore together?",
          subtitle: 'We prepared some quick tips for you to take the first steps.',
        },
        {
          title: 'Set the categories',
          subtitle: "Define the expense's categories that will be selected when registering an expense.",
        },
        {
          title: 'Set limits to expenses',
          subtitle: 'Define a maximum value that the employee can have in one or multiple categories.',
        },
        {
          labelButtonAction: 'Finish',
          labelButtonCancel: 'Back',
          title: 'Start sharing the platform to your employees!',
          subtitle: 'and send an access invite to the new platform.',
          iconLabel: 'Done!',
          link: 'Click here',
        },
      ],
      launchMileageExpensePreview: {
        titleNewExpense: 'New mileage expense',
        titleEditExpense: 'Edit mileage expense',
        tabs: {
          originAndDestiny: 'Origin and destiny',
          odometer: 'Odometer',
          gps: 'GPS',
        },
      },
      waypointFields: {
        fieldLabel: {
          origin: 'Origin',
          stop: 'Stop',
          destination: 'Destination',
        },
        button: {
          open: 'Show summary route',
          close: 'Show full route',
        },
      },
      expensesTipsModal: [
        {
          labelButtonAction: 'Show me hoe to navigate',
          labelButtonCancel: 'No, thanks',
          title: 'Prestar contas de despesas ficou muito mais fácil!',
          iconLabel: "Let's explore together?",
          subtitle: 'We prepared some quick tips for you to start taking your first steps in the platform.',
        },
        {
          title: 'Add an expense',
          subtitle: 'Create a new expense and select the report it belongs to. If needed, create a new report.',
        },
        {
          title: 'Fill the information',
          subtitle: 'Attach the invoice and select how you want to fill the fields.',
        },
        {
          title: 'Submit the report for approval',
          subtitle: 'Submit the report with the created expense to approval',
        },
        {
          labelButtonAction: 'Finish',
          labelButtonCancel: 'Go back',
          title: "Now you're ready to send your expenses",
          iconLabel: 'All set!',
          subtitle: 'If you still have any doubts, take a look at',
          link: 'Flash Help Center',
        },
      ],
      approvals: {
        tabs: {
          reports: 'Reports',
          advances: 'Advances',
          companyPayables: 'Vendor expenses',
        },
        filters: {
          label: {
            isPendingApproval: 'Pending',
            requester: 'Requester',
            startDate: 'Start date',
            status: 'Status',
            submissionDate: 'Submission date',
            reportCreationDate: 'Creation date',
            search: 'Search report by name or reference',
          },
        },
      },
      confirmReportApprovalModal: {
        attention: 'Attention!',
        conclude: 'Conclude',
        pluralForm: {
          popupTitle: 'Are you sure you want to refund the {{reportsSelected}} selected reports?',
          popupSubtitle: 'The reports will be forwarded to financial analysis.',
        },
        singularForm: {
          popupTitle: 'Are you sure you want to refund the report?',
          popupSubtitle: 'The report will be forwarded to financial analysis.',
        },
      },
      approvalReportsPreview: {
        pluralForm: {
          approvedSuccessMessage: 'Reports successfully approved!',
          disapprovedSuccessMessage: 'Reports failed Successfully!',
        },
        singularForm: {
          approvedSuccessMessage: 'Report successfully approved!',
          disapprovedSuccessMessage: 'Report failed successfully!',
        },
      },
      ApprovalsReportGrid: {
        report: 'Report',
        actionsOptions: {
          view: 'View',
          exportPdf: 'Export PDF',
          exportPdfByCategory: 'Export PDF by category',
          approve: 'Approve',
          reprove: 'Reprove',
          tooltip: {
            approve: 'Only reports with an "Submitted" status can be approved',
            reprove: 'Only reports with an "Submitted" status can be reproved',
            exportPdf: '',
            exportPdfByCategory: '',
            exportExcel: 'To carry out the export to excel it is necessary to filter a start period',
          },
          feedbacks: {
            export: {
              pluralForm: {
                error: 'An error occurred while exporting reports',
              },
              singularForm: {
                error: 'An error occurred while exporting report',
              },
            },
          },
        },
        statusOptions: {
          open: 'Open',
          reimbursed: 'Reimbursed',
          submitted: 'Submitted',
          reproved: 'Reproved',
          approved: 'Approved',
        },
        pendingOptions: {
          true: 'Your approval',
          false: 'Any approval',
        },
        selectedItems: '{{selectedItems}} out of {{totalItems}} selected',
      },
    },
    delegation: {
      configuration: {
        title: 'Delegation of actions',
        descriptionFieldPeople: 'Select the target person for delegation',
        labelPeople: 'Person',
        toDelegate: 'To delegate',
        expenseDelegation: {
          title: 'Delegation of accounting for corporate expenses',
          caption: 'Users allowed to account for the selected user.',
        },
        approvalDelegation: {
          title: 'Delegation of approval of reports',
          caption: 'Users allowed to approve reports by selected person.',
          legacy: {
            title: 'Delegation of approval of reports and advances',
            caption: 'Users allowed to approve reports and advances by selected person.',
          },
        },
        grid: {
          header: {
            person: 'Person',
            startDate: 'Start date',
            endDate: 'End date',
            status: 'Status',
            actions: 'Actions',
            delete: 'Delete',
            reason: 'Reason',
            disable: 'Disable',
            tooltips: {
              disable: 'Only "Active" statuses can be changed.',
            },
          },
          body: {
            active: 'Active',
            inactive: 'Inactive',
            selectUser: 'Select who you want to delegate to!',
            emptyData: 'The selected user does not have people with delegation permission!',
          },
        },
        tooltip: {
          buttonAddSelectUser: 'Select who you want to delegate to.',
        },
        add: {
          cancel: 'Cancel',
          add: 'Add',
          requiredField: 'Required field',
          allowedActions: 'Allowed actions',
          fields: {
            person: 'Person',
            duration: 'Duration',
            shortPeriod: 'Short period',
            noDeadline: 'No deadline',
            startDate: 'Initial date',
            endDate: 'Final date',
            reason: 'Reason',
          },
          expense: {
            title: 'Delegate account provider',
            caption: 'Select the user who will have access to report on your behalf.',
            allowedActions: {
              createAndSend: 'Create and submit expense reports for approval',
              accessCards: 'Access corporate cards and account payments',
              editAndLink: 'Edit and link reports to corporate card expenses',
              accessReports: 'Access reports',
            },
          },
          approval: {
            title: 'Delegate approver',
            caption:
              'Choose the user who will have access to approve reports and advances in place of the selected person.',
            allowedActions: {
              approveAndReproveReports: 'Approve and disapprove reports submitted for approval',
              approveAndReproveRequests: 'Approve and disapprove advance requests',
            },
          },
        },
        feedbacks: {
          attention: 'Attention!',
          deleteModal: {
            title: {
              plural: 'Are you sure you want to disable these people from delegation?',
              singular: 'Are you sure you want to deactivate this person from the delegation?',
            },
            description: {
              plural: 'By deactivating, these people could not approve on your behalf.',
              singular: 'By deactivating, that person will not be able to approve on your behalf.',
            },
            confirm: {
              plural: 'Confirm deactivations',
              singular: 'Confirm deactivation',
            },
          },
          disableModal: {
            title: {
              plural: 'Are you sure you want to exclude these people from the delegation?',
              singular: 'Are you sure you want to exclude these people from the delegation?',
            },
            description: {
              plural:
                'When deleting, all data related to them will be lost, and these people could not approve on your behalf.',
              singular:
                'When you delete it, all data related to it will be lost, and that person will not be able to approve on your behalf.',
            },
            confirm: {
              plural: 'Confirm exclusions',
              singular: 'Confirm exclusion',
            },
          },
          create: {
            success: {
              approval: 'Approvals delegate successfully added!',
              expense: 'Person to accountability delegate added successfully!',
            },
            error: {
              approval: 'Unable to add person {{ userName }} for delegation.',
              expense: 'Unable to add person {{ userName }} for delegation',
            },
          },
        },
      },
    },
    approversDrawer: {
      title: 'Report approvers',
      caption: 'Check the approvers and their status in relation to the report.',
      approved: 'Approved',
      disapproved: 'Disapproved',
      pendingApproval: 'Pending approval',
    },
    report: {
      reportDetails: 'Report Details',
      totalExpenses: 'Total expenses',
      reimbursableValue: 'Reimbursable value',
      approver: 'Approver',
      approvers: 'Approvers',
      dateLimit: 'Limit Date',
      toRecall: 'To recall',
      seeDetails: 'See details',
      finality: 'Finality',
      reimbursableExpenses: 'Reimbursable Expenses',
      nonRefundableExpenses: 'Non-refundable Expenses',
      advances: 'Advances',
      withdrawals: 'Withdrawals',
      period: 'Period',
      to: 'to',
      historic: 'Historic',
      expenses: 'Expenses',
      unnamedReport: 'Unnamed report',
      reportName: 'Report Name',
      newExpense: 'Add expense',
      linkExpense: 'Link expense',
      uninformed: 'Uninformed',
      requester: 'Requester',
      advance: 'Advances',
      withdraw: 'Withdrawals',
      reprovalMessageTitle: 'Reason for disapproval',
      status: {
        open: 'Open',
        submitted: 'Submitted',
        approved: 'Approved',
        reproved: 'Reproved',
        reimbursed: 'Reimbursed',
        awaitingFinancialProcessing: 'Awaiting Processing',
        processingPayment: 'Processing Payment',
        processingPaymentError: 'Processing Payment Error',
        undefined: 'Status not reported',
      },
      reportHistoryGrid: {
        date: 'Date',
        user: 'User',
        actions: 'Actions',
        title: 'History',
      },
      filters: {
        label: {
          createdBy: 'Requester',
          reportCreationDate: 'Creation date',
          reportEndDate: 'End date',
          reportStartDate: 'Period',
          search: 'Search report by name or reference',
          status: 'Status',
          moreFilters: 'More filters',
          periodDate: 'Period',
          awaitingImmediateApproverDecision: 'Pending',
        },
      },
      actions: {
        exportToExcel: 'Export to excel',
      },
    },
    withdraw: {
      feedbacks: {
        attach: {
          success: {
            plural: 'Withdrawal linked successfully!',
            singular: 'Withdrawals linked successfully!',
          },
          error: 'An error occurred while linking withdrawal.',
        },
        detach: {
          success: {
            plural: 'Withdrawals successfully unlinked!',
            singular: 'Withdrawal successfully unlinked!',
          },
          error: 'An error occurred while unlinking withdrawal.',
        },
        tooltips: {
          notDetach: {
            plural: 'Withdrawals can only be unlinked in reports with OPEN status.',
            singular: 'Withdraw can only be unlinked in reports with OPEN status.',
          },
        },
      },
      grid: {
        header: {
          columns: {
            card: 'Card',
            value: 'Value',
            date: 'Date',
            actions: 'Actions',
          },
        },
        body: {
          actions: {
            link: 'Link withdrawal',
            unlink: 'Unlink withdrawal',
          },
        },
        tooltips: {
          notDetach: {
            plural: 'Withdrawals can only be unlinked in reports with OPEN status.',
            singular: 'Withdraw can only be unlinked in reports with OPEN status.',
          },
        },
      },
      attachWithdrawModal: {
        title: 'Attach withdraw to report',
        subtitle: 'Select the withdrawals that you want to attach to the report.',
        add: 'Add',
        empty: 'No withdraw available for linking!',
      },
      modal: {
        detachConfirmation: {
          iconDescription: 'Attention!',
          title: {
            plural: 'Are you sure you want to unlink the selected withdrawals?',
            singular: 'Are you sure you want to unlink this withdraw?',
          },
          message: {
            plural: 'The withdrawals will return to the list, and you will need to link them to a report again.',
            singular: 'The withdraw will be returned to the listing, and you will need to link it to a report again.',
          },
          actions: {
            confirm: {
              plural: 'Confirm unlinks',
              singular: 'Confirm unlink',
            },
          },
        },
      },
    },
    analytics: {
      actions: {
        exportCSV: 'Export CSV',
        exportPDF: 'Export PDF',
        exportXLSX: 'Export XLSX',
        loading: 'Please wait for the information to load before exporting!',
        seeDetails: 'See details',
        actions: 'Actions',
      },
      sections: {
        expenses: {
          title: 'Expenses',
          caption: 'Generate comprehensive reports to analyze your organizations corporate spending.',
        },
        reports: {
          title: 'Reports',
          caption: 'Review accountability reports.',
        },
        advances: {
          title: 'Advances',
          caption: 'Review reports related to down payment requests.',
        },
        logs: {
          title: 'Logs',
          caption: 'Analyze the logs of changes made to the platform.',
        },
      },
      filters: {
        label: {
          expenseDate: 'Date',
          expenseCategory: 'Category',
          expensePaymentMethod: 'Payment method',
          expenseCorporateCardNumber: 'Corporate card nº',
          user: 'Applicant name',
          expenseSubsidiary: 'Branch',
          expenseArea: 'Area',
          expenseHasViolations: 'Out-of-policy expense identifier',
          expensePosition: 'Position',
          expenseCosteCenter: 'Cost center',
          expenseClient: 'Client',
          expenseProject: 'Project',
          createdDate: 'Created date',
          value: 'Value',
          reimbursableValue: 'Reimbursable value',
          report: 'Report',
          reportLastApprover: 'Last approver',
          reportStatus: 'Report status',
          reportCreationDate: 'Report creation date',
          reportApprovalDate: 'Approval date',
          reportPaymentSubmitDate: 'Payment submit date',
          reportPaymentDate: 'Payment date',
          reportCurrentApprover: 'Current approver',
          advanceStatus: 'Advance status',
          advanceCreationDate: 'Advance creation date',
          logDate: 'Data',
          logAction: 'Action',
          logParameter: 'Parameter',
          logUser: 'User who performed the action',
          logApprovalFlow: 'Approval flow',
          logArea: 'Area',
          logCostCenter: 'Cost center',
          logProject: 'Project',
          logPolicy: 'Policy',
          logUserTarget: 'User who received the action',
        },
      },
      advancesOverview: {
        title: 'Overview',
        fileName: 'advance-report',
      },
      advancesUsers: {
        title: 'Status per requester',
        fileName: 'advance-report-status-per-requester',
      },
      expenseOverview: {
        title: 'Overview',
        fileName: 'expenses-report',
        columns: {
          date: 'Date',
          id: 'ID',
          requesterName: 'Requester',
          category: 'Category',
          currency: 'Currency',
          value: 'VAlue',
          receipt: 'Receipt',
          reportCode: 'Report code',
          reportStatus: 'Report status',
        },
      },
      expenseCategories: {
        title: 'Expenses by category',
        fileName: 'report-by-category',
        columns: {
          description: 'Category',
          quantity: 'Quantity',
          value: 'Value',
        },
      },
      expenseRequester: {
        title: 'Expenses by requester',
        fileName: 'expense-report-by-applicant',
        columns: {
          requesterName: 'Requester name',
          quantity: 'Quantity',
          value: 'Value',
        },
      },
      reportOverview: {
        title: 'Overview',
        fileName: 'overview-reports',
        columns: {
          reportName: 'Report name',
          id: 'ID',
          value: 'Value',
          status: 'Status',
          createAt: 'create at',
          reimbursableValue: 'Reimbursable value',
          currency: 'Currency',
          user: 'Requester',
          approver: 'Last approver',
        },
      },
      reportStatusByRequester: {
        title: 'Status by requester',
        fileName: 'report-status-per-requester',
        columns: {
          requesterName: 'Requester name',
          status: 'Status',
          quantity: 'Quantity',
          value: 'Value',
          reimbusableValue: 'Refundable value',
        },
      },
      reportsPendingApprovalsByUsers: {
        title: 'Approvals pending by approver',
        fileName: 'report-approvals-pending-by-approver',
        columns: {
          approverOfTheTime: 'Approver the time',
          quantityOfReports: 'Quantity Reports',
          currency: 'Currency',
          value: 'Value',
          reimbusableValue: 'Reimbursable value',
          createdAt: 'createdAt',
        },
      },
      expenseCorporateCardOverview: {
        title: 'Corporate card expenses',
        fileName: 'overview-expenses-corporate-card',
        columns: {
          expenseDate: 'Data despesa',
          expenseId: 'Expense date',
          requesterName: 'Applicant name',
          cardNumber: 'Nº corporate card',
          categoryName: 'Category name',
          currency: 'Currency',
          value: 'Value',
          receipt: 'Receipt',
          reportCode: 'Report code',
          statusReport: 'Status report',
        },
      },
      expenseCorporateCardPendenciesPerUser: {
        title: 'Pending per applicant',
        fileName: 'corporate-expenses-pending-per-applicant',
        columns: {
          requesterName: 'Applicant name',
          cardNumber: 'Nº corporate card',
          pendenciesNumber: 'Nº outstanding expenses',
          value: 'Value',
        },
      },
      corporateCardTransactions: {
        title: 'Transactions',
        modal: {
          title: 'Download report',
          subtitle: 'Transactions report',
          dateRangeLabel: 'Select the date range',
          dateRangeInputLabel: 'Date range',
        },
      },
      corporateCardActiveUsers: {
        title: 'Active Users',
        modal: {
          title: 'Download report',
          subtitle: 'Active users report',
          dateRangeLabel: 'Select the date range',
          dateRangeInputLabel: 'Date range',
        },
      },
      corporateCardFlashCashStatement: {
        title: 'Flash Cash Statement',
        modal: {
          title: 'Download report',
          subtitle: 'Flash Cash statement report',
          dateRangeLabel: 'Select the date range',
          dateRangeInputLabel: 'Date range',
        },
      },
      corporateCardMonthlyFlashCash: {
        title: 'Monthly Flash Cash Statement',
        modal: {
          title: 'Download report',
          subtitle: 'Monthly Flash Cash statement report',
          dateRangeLabel: 'Select the date range',
          dateRangeInputLabel: 'Date range',
        },
      },
      logs: {
        approval: {
          title: 'Approvals',
        },
        area: {
          title: 'Area',
        },
        costCenter: {
          title: 'Cost center',
        },
        project: {
          title: 'Project',
        },
        policy: {
          title: 'Policy',
        },
        user: {
          title: 'User',
        },
        modal: {
          logDetails: 'Log details',
          userIP: 'User IP',
          feature: 'Feature',
          viaAttendance: 'Via Flash Expense attendance',
          changedData: 'Updated field',
          newValue: 'New value',
          oldValue: 'Old value',
        },
        common: {
          yes: 'Yes',
          no: 'No',
          previousValue: 'Previous value',
          newValue: 'New value',
        },
      },
      toasts: {
        error: {
          title: 'An error occurred while creating the report',
          message: 'Try again later. If the problem persists, please contact support.',
        },
        success: {
          title: 'Report created successfully',
          message: 'The file was saved on your computer.',
        },
      },
      customizeReport: {
        availableFields: 'Available fields',
        selectedFields: 'Selected fields',
        applyAndSave: 'Save and apply',
        apply: 'Apply',
        cancel: 'Cancel',
        customizeReport: 'Customize report',
        fields: 'Fields',
        tooltip: 'At least one field must be selected!',
        tooltipButtonTransfer: 'Select 1 or more fields to move.',
        feedback: {
          success: 'Report customization saved successfully!',
          error: 'An error occurred while saving the report customization.',
        },
      },
    },
    clients: {
      page: {
        title: 'Clients',
        caption: 'Manage the clients of your company that will appear in corporate expenses allocation.',
        breadcrumb: {
          clients: 'Clients',
          manageCompany: 'Manage company',
        },
      },
      clientsPreview: {
        clientsCounter: {
          label: 'Projects',
          items: {
            plural: '{{items}} items',
            singular: '{{items}} item',
          },
        },
      },
      clientsGridPreview: {
        items: {
          plural: 'items',
          singular: 'item',
        },
        title: 'Clients registered',
        search: 'Search by client',
      },
      addClient: {
        buttonLabel: 'Add client',
        options: {
          addClientManually: 'Add client manually',
          addClientByFile: 'Add client by file',
        },
      },
      grid: {
        searchFieldLabel: 'Search by client',
        active: 'Active',
        inactive: 'Inactive',
        uninformedClient: 'Name not informed',
        uninformedEmailClient: 'E-mail not informed',
        header: {
          columns: {
            client: 'Client',
            phone: 'Phone',
            status: 'Status',
            actions: 'Actions',
            uninformedEmailClient: 'E-mail not informed',
          },
          batchActions: {
            deactivate: 'Deactivate',
            activate: 'Activate',
            delete: 'Delete',
            tooltips: {
              active: 'Select active projects',
            },
          },
        },
        body: {
          actions: {
            edit: 'Edit project',
            deactivate: 'Deactivate project',
            active: 'Activate project',
            delete: 'Delete project',
          },
        },
      },
      registerClient: {
        title: 'General data',
        subTitle: 'Insert the clients basic data.',
        assignUsersDescription:
          'As standard all people can register expenses to this client, if needed, select specific people to assign.',
        required: 'required field',
        breadcrumbs: {
          clients: 'Clients',
          manageCompany: 'Manage company',
          registerClient: 'Register client',
        },
        assignUsersGrid: {
          allPeople: 'All people',
          selectedForClient: 'Selected for this client',
          all: 'All ({{selected}})',
          selected: 'Selected ({{selected}})',
          header: {
            name: 'Name',
          },
        },
        steps: {
          header: {
            generalData: 'General data',
            users: 'Assign users',
          },
          pageContent: [
            {
              title: 'General Data',
              sectionTitle: 'General Data',
              subTitle: "Insert the client's basic data",
            },
            {
              title: 'Assign users',
              sectionTitle: 'Assign users',
              subTitle: 'Choose which people can register expenses to this project.',
            },
          ],
        },
      },
      registerClientForm: {
        name: "Client's name",
        phoneNumber: "Client's Phone",
        email: "Client's E-mail",
        website: "Client's Site",
        externalCode: 'Code',
        externalCodeHelper: 'Insert the code used by your company for better management.',
        active: 'Active client',
        activeDescription: 'When active, the client shows as an option when registering an expense.',
        error: {
          fieldRequired: '{{field}} field must be filled',
        },
      },
      clientsGrid: {
        client: 'Client',
        phone: 'Phone',
        status: 'Status',
        actions: 'Actions',
        active: {
          true: 'Active',
          false: 'Inactive',
        },
        actionsGrid: {
          singularForm: {
            edit: 'Edit',
            delete: 'Delete',
            activate: 'Activate',
            deactivate: 'Deactivate',
          },
          pluralForm: {
            delete: 'Delete',
            activate: '',
            deactivate: '',
          },
        },
        selectedItems: '{{selectedItems}} out of {{totalItems}} selected',
      },
      modal: {
        exclusion: {
          iconDescription: 'Attention!',
          title: {
            plural: 'Are you sure you want to delete selected clients?',
            singular: 'Are you sure you want to delete this client?',
          },
          message: {
            plural: 'All data related to them will be lost and this action cannot be undone.',
            singular: 'All data related to it will be lost and this action cannot be undone.',
          },
          actions: {
            confirm: {
              plural: 'Confirm deletions',
              singular: 'Confirm deletion',
            },
          },
        },
        deactivation: {
          iconDescription: 'Attention!',
          title: {
            plural: 'Are you sure you want to deactivate the selected clients?',
            singular: 'Are you sure you want to deactivate this client?',
          },
          message: {
            plural: 'By disabling them, clients will no longer appear in the client listing when posting expenses.',
            singular:
              'By deactivating it, the client will no longer appear in the client listing when posting expenses.',
          },
          actions: {
            confirm: {
              plural: 'Confirm deactivations',
              singular: 'Confirm deactivation',
            },
          },
        },
      },
    },
    subsidiaries: {
      page: {
        title: 'Subsidiaries',
        caption: 'Register the business units to which employees are linked.',
        breadcrumb: {
          manageCompany: 'Manage company',
          subsidiaries: 'Subsidiaries',
          add: 'Add subsidiary',
          edit: 'Edit subsidiary',
        },
      },
      subsidiariesPreview: {
        subsidiariesCounter: {
          label: 'Subsidiaries',
          items: {
            plural: '{{items}} items',
            singular: '{{items}} item',
          },
        },
      },
      addSubsidiaries: {
        buttonLabel: 'Add subsidiary',
      },
      grid: {
        searchFieldLabel: 'Search by subsidiary',
        active: 'Active',
        inactive: 'Inactive',
        header: {
          columns: {
            subsidiary: 'Subsidiary',
            uf: 'State',
            cnpj: 'CNPJ',
            status: 'Status',
            actions: 'Actions',
          },
          batchActions: {
            deactivate: 'Deactivate',
            activate: 'Activate',
            delete: 'Delete',
            tooltips: {
              active: 'Select active subsidiaries',
            },
          },
        },
        body: {
          actions: {
            edit: 'Edit subsidiary',
            deactivate: 'Deactivate subsidiary',
            active: 'Activate subsidiary',
            delete: 'Delete subsidiary',
          },
        },
      },
      modal: {
        exclusion: {
          iconDescription: 'Attention!',
          title: {
            plural: 'Are you sure you want to delete selected subsidiaries?',
            singular: 'Are you sure you want to delete this subsidiary?',
          },
          message: {
            plural: 'All data related to them will be lost and this action cannot be undone.',
            singular: 'All data related to it will be lost and this action cannot be undone.',
          },
          actions: {
            confirm: {
              plural: 'Confirm deletions',
              singular: 'Confirm deletion',
            },
          },
          feedbacks: {
            success: {
              title: 'Subsidiary {{name}}',
              message: 'successfully deleted',
            },
            error: {
              title: 'Error when trying to delete',
              message: 'subsidiary {{name}}',
            },
          },
        },
        deactivation: {
          iconDescription: 'Attention!',
          title: {
            plural: 'Are you sure you want to deactivate the selected subsidiaries?',
            singular: 'Are you sure you want to deactivate this subsidiary?',
          },
          message: {
            plural: 'When you deactivate them, the business units will no longer be available to link to an employee',
            singular: 'When deactivating it, the business unit will no longer be available for linking to an employee.',
          },
          actions: {
            confirm: {
              plural: 'Confirm deactivations',
              singular: 'Confirm deactivation',
            },
          },
        },
      },
      feedbacks: {
        error: 'An error occurred while trying to get subsidiaries',
      },
    },
    projects: {
      page: {
        title: 'Projects',
        caption: 'Create the projects where expenses will be posted.',
        breadcrumb: {
          manageCompany: 'Manage company',
        },
      },
      registerProject: {
        steps: {
          header: {
            generalData: 'General data',
            users: 'Assign users',
          },
          pageContent: [
            {
              title: 'General Data',
              sectionTitle: 'General Data',
              subTitle: "Insert the client's basic data",
            },
            {
              title: 'Assign users',
              sectionTitle: 'Assign users',
              subTitle: 'Choose which people can register expenses to this project.',
            },
          ],
        },
      },
      projectsPreview: {
        projectsCounter: {
          label: 'Projects',
          items: {
            plural: '{{items}} items',
            singular: '{{items}} item',
          },
        },
      },
      addProjects: {
        buttonLabel: 'Add project',
        options: {
          addProjectManually: 'Add project manually',
          addProjectByFile: 'Add project by file',
        },
      },
      grid: {
        searchFieldLabel: 'Search by project',
        active: 'Active',
        inactive: 'Inactive',
        header: {
          columns: {
            projects: 'Projects',
            clients: 'Clients',
            accountable: 'Accountable',
            period: 'Period',
            status: 'Status',
            actions: 'Actions',
          },
          batchActions: {
            deactivate: 'Deactivate',
            activate: 'Activate',
            delete: 'Delete',
            tooltips: {
              active: 'Select active projects',
            },
          },
        },
        body: {
          actions: {
            edit: 'Edit project',
            deactivate: 'Deactivate project',
            active: 'Activate project',
            delete: 'Delete project',
          },
        },
      },
      modal: {
        exclusion: {
          iconDescription: 'Attention!',
          title: {
            plural: 'Are you sure you want to delete selected projects?',
            singular: 'Are you sure you want to delete this project?',
          },
          message: {
            plural: 'All data related to them will be lost and this action cannot be undone.',
            singular: 'All data related to it will be lost and this action cannot be undone.',
          },
          actions: {
            confirm: {
              plural: 'Confirm deletions',
              singular: 'Confirm deletion',
            },
          },
        },
        deactivation: {
          iconDescription: 'Attention!',
          title: {
            plural: 'Are you sure you want to deactivate the selected projects?',
            singular: 'Are you sure you want to deactivate this project?',
          },
          message: {
            plural: 'By disabling them, projects will no longer appear in the project listing when posting expenses.',
            singular:
              'By deactivating it, the project will no longer appear in the project listing when posting expenses.',
          },
          actions: {
            confirm: {
              plural: 'Confirm deactivations',
              singular: 'Confirm deactivation',
            },
          },
        },
      },
    },
    approvalFlows: {
      pageGrid: {
        title: {
          reports: 'Report approval flow',
          advances: 'Advance approval flow',
        },
        addButton: 'Add flow',
        breadcrumb: {
          approvals: 'Approvals',
          manageApprovals: 'Manage approvals',
        },
      },
      pageRegister: {
        title: {
          edit: 'Edit approval flow',
          create: 'Add approval flow',
        },
        progress: {
          basicInformation: 'Basic information',
          approvalType: 'Type of approval',
        },
        breadcrumb: {
          approvals: 'Approvals',
          manageApprovals: 'Manage approvals',
          report: 'Report approval flow',
          advance: 'Advance approval flow',
          create: 'Add approval flow',
          edit: 'Edit approval flow',
        },
        infos: {
          basicInformation: {
            title: 'Basic information',
            description:
              'Edit the basic information of the approval flow, and what the criteria for activation will be.',
          },
          approvalType: {
            title: 'Type of approval',
            description: 'Select how approval by approvers will take place.',
            important: {
              title: 'Important:',
              item1: {
                advance:
                  'When the requester is also the approver of the advance, the approval is carried out automatically, going to the other approvers, if any.',
                report:
                  'When the requester is also the approver of the report, approval is carried out automatically, going to the other approvers, if any.',
              },
              item2: 'If an approver is involved in multiple steps of the flow, they only need to approve once.',
              item3: {
                advance:
                  'If the advance meets the criteria of 2 approval streams, it will only request the first level of approval.',
                report:
                  'If the report meets the criteria of 2 approval streams, it will only request the first level of approval.',
              },
            },
          },
        },
        buttons: {
          cancel: 'Cancel',
          continue: 'Continue',
          save: 'Save',
          back: 'Back',
        },
        loader: {
          edit: 'Saving changes...',
          create: 'Creating flow...',
        },
      },
      reorderingModal: {
        title: 'Reorder streams',
        caption:
          'Drag the lines and reorder the flows according to the desired priority. You can also change directly from the home screen listing.',
        cancel: 'Cancel',
        save: 'Save',
      },
      grid: {
        searchFieldLabel: 'Search by approval flow',
        active: 'Active',
        inactive: 'Inactive',
        header: {
          columns: {
            priority: 'Priority',
            name: 'Stream name',
            approvalType: 'Type of approval',
            status: 'Status',
            description: 'Description',
            actions: 'Actions',
          },
          batchActions: {
            deactivate: 'Disable',
            activate: 'Activate',
            delete: 'Delete',
            tooltips: {
              active: 'Select active approval flows.',
            },
          },
        },
        body: {
          actions: {
            edit: 'Edit flow',
            deactivate: 'Disable flow',
            active: 'Activate flow',
            delete: 'Delete stream',
            duplicate: 'Duplicate flow',
          },
          approvalType: {
            manual: 'Manual',
            automaticApproval: 'Automatic approval',
            automaticRefusal: 'Automatic refusal',
          },
        },
      },
      basicInformation: {
        title: 'Basic information',
        link: 'Reorder streams',
        fields: {
          name: {
            label: 'Stream name',
            mandatoryInfo: 'The flow name field is mandatory!',
            charactersInfo: 'Name must contain between 4 and 60 characters!',
          },
          priority: {
            label: 'Priority',
            helperText: 'If you want to create a flow with a priority already used, reorder the flows.',
          },
          description: {
            label: 'Flow description',
            mandatoryInfo: 'The flow description field is mandatory!',
            charactersInfo: 'Description must contain between 4 and 250 characters!',
          },
          mandatoryInfo: 'Required field.',
        },
      },
      approvalCriteria: {
        title: 'Approval criteria',
        caption: 'Insert conditionals that, when reached, will trigger the approval flow.',
        link: 'Add conditional',
        tooltipLink: 'Fill in all the conditional fields to add new ones!',
        fields: {
          labelMain: 'When',
          field: {
            label: 'Conditional',
            mandatoryInfo: 'Required field!',
            tooltip: 'Complete the previous conditional.',
          },
        },
        options: {
          statement: {
            and: 'And',
            or: 'Or',
          },
          criterion: {
            area: 'Area',
            jobPosition: 'Job position',
            subsidiary: 'Subsidiary',
            costCenterResponsible: 'Cost center responsible',
            responsibleForTheProject: 'Responsible for the project',
            category: 'Category',
            totalReportValue: 'Total report value',
            totalAdvanceValue: 'Advance value',
            totalViolations: 'Total violations',
          },
          operator: {
            equals: 'equals',
            greaterThan: 'greater than',
            greaterThanOrEqual: 'greater than or equal',
            notEqual: 'not equal',
            lesserThan: 'lesser than',
            lesserThanOrEqual: 'lesser than or equal',
          },
        },
      },
      approvalSources: {
        automaticApproval: {
          title: 'Automatic approval',
          caption: {
            advance:
              'When submitting the advance, it is automatically sent to the finance department, without the need for approval from an approver.',
            report:
              'When submitting the report, it is automatically sent to finance, without the need for approval from an approver.',
          },
        },
        automaticDisapproval: {
          title: 'Automatic disapproval',
          caption: {
            advance: 'If the criteria established in the previous step are met, the advance is automatically rejected.',
            report: 'If the criteria established in the previous step are met, the report is automatically rejected.',
          },
          toggle: 'Configure rejection message',
          fieldLabel: 'Rejection message',
          mandatoryInfo: 'Required field!',
        },
        approvalByLevels: {
          title: 'Manual approval',
          caption: 'Define who will be the approver, and it is possible to add more than one approver to the flow.',
          buttonLink: 'Add level',
          tooltipLink: 'Fill in the fields to add a new level.',
          fields: {
            criterion: {
              label: 'Criterion',
              mandatoryInfo: 'Required field!',
            },
            hierarchyLevels: {
              label: 'Hierarchy levels',
              mandatoryInfo: 'Required field!',
            },
            people: {
              label: 'People',
              mandatoryInfo: 'Required field!',
            },
          },
        },
        optionsCriteriaSource: {
          costCenterOwner: {
            label: 'Responsible for the cost center',
            caption: {
              report:
                'Approval is carried out by the person responsible for the cost center associated with the expense.',
              advance:
                'Approval is carried out by the person responsible for the cost center associated with the advance.',
            },
          },
          costCenterOwnerWithoutDefault: {
            label: 'Automatic approval when the cost center is the requestor`s default',
          },
          immediateManager: {
            label: 'Single Level',
            caption: 'If A sends to B, the approver must be B',
          },
          secondLevelManager: {
            label: 'Two Levels',
            caption: 'If A sends to B and B to C, the approval flow will be B => C',
          },
          thirdLevelManager: {
            label: 'Three Levels',
            caption: 'If A sends to B, B to C and C to D, the approval flow will be B => C => D',
          },
          fourthLevelManager: {
            label: 'Four Levels',
            caption: 'If A sends to B, B to C, C to D and D to E, the approval flow will be B => C => D => E',
          },
          fifthLevelManager: {
            label: 'Five Levels',
            caption:
              'If A sends to B, B to C, C to D, D to E and E to F, the approval flow will be B => C => D => E => F',
          },
          manual: {
            label: 'Choose approver manually',
            caption: 'Select a person with an approval profile to approve.',
          },
          projectOwner: {
            label: 'Responsible for the project',
            caption: {
              report: 'Approval is carried out by the person responsible for the project associated with the expense.',
              advance: 'Approval is carried out by the person responsible for the project associated with the advance.',
            },
          },
          approvalByHierarchy: {
            label: 'Approval by hierarchy',
            caption: {
              report: 'Approvers registered from the immediate manager receive the report to approve.',
              advance: 'Approvers registered from the immediate manager receive the advance payment to approve.',
            },
          },
        },
      },
      modal: {
        exclusion: {
          iconDescription: 'Attention!',
          title: {
            plural: 'Are you sure you want to delete the selected approval flows?',
            singular: 'Are you sure you want to delete the selected approval flow?',
          },
          message: {
            plural: 'All data related to them will be lost and this action cannot be undone.',
            singular: 'All data related to it will be lost and this action cannot be undone.',
          },
          actions: {
            confirm: {
              plural: 'Confirm exclusions',
              singular: 'Confirm deletion',
            },
          },
        },
        deactivation: {
          iconDescription: 'Attention!',
          title: {
            plural: 'Are you sure you want to disable the selected approval flows?',
            singular: 'Are you sure you want to disable the selected approval flow?',
          },
          message: {
            plural: 'When you disable them, they will no longer be applied.',
            singular: 'When you deactivate it, it will no longer be applied.',
          },
          actions: {
            confirm: {
              plural: 'Confirm deactivations',
              singular: 'Confirm deactivation',
            },
          },
        },
      },
      feedbacks: {
        toggle: {
          success: {
            plural: 'Status of approval flows updated successfully!',
            singular: 'Approval flow status updated successfully!',
          },
          error: 'An error occurred when changing approval flow status',
        },
        duplicate: {
          success: 'Duplication completed successfully!',
          error: 'An error occurred while duplicating approval flow.',
        },
        delete: {
          success: {
            plural: 'Successfully deleted approval flows!',
            singular: 'Successfully deleted approval flow!',
          },
          error: 'An error occurred while deleting the approval flow',
        },
        create: {
          success: 'Approval flow created successfully!',
          error: 'An error occurred while creating approval flow.',
        },
        edit: {
          success: 'Changes made successfully!',
          error: 'An error occurred while editing approval flow.',
        },
        byId: {
          error: 'Error opening stream, try again!',
        },
      },
    },
    costCenters: {
      page: {
        title: 'Cost center',
        caption: 'Create cost centers that can be selected when posting expenses.',
        breadcrumb: {
          manageCompany: 'Manage company',
        },
      },
      listPreview: {
        counterData: {
          label: 'Cost center',
          items: {
            plural: '{{ items }} items',
            singular: '{{ items }} item',
          },
        },
      },
      addButton: {
        buttonLabel: 'Add cost center',
        options: {
          addManually: 'Add cost center per platform',
          addByFile: 'Add cost center per file',
        },
      },
      grid: {
        searchFieldLabel: 'Search by cost center',
        active: 'Active',
        inactive: 'Inactive',
        header: {
          columns: {
            costCenter: 'Cost center',
            status: 'Status',
            responsible: 'Responsible',
            groupCompany: 'Group company',
            department: 'Department',
            code: 'Code',
            project: 'Project',
            actions: 'Actions',
          },
          batchActions: {
            deactivate: 'Deactivate',
            activate: 'Activate',
            delete: 'Delete',
            tooltips: {
              active: 'Select active cost centers.',
            },
          },
        },
        body: {
          actions: {
            edit: 'Edit cost center',
            deactivate: 'Deactivate cost center',
            active: 'Activate cost center',
            delete: 'Delete cost center',
          },
        },
      },
      modal: {
        exclusion: {
          iconDescription: 'Attention!',
          title: {
            plural: 'Are you sure you want to delete the selected cost centers?',
            singular: 'Are you sure you want to delete this cost center?',
          },
          message: {
            plural: 'All data related to them will be lost and this action cannot be undone.',
            singular: 'All data related to it will be lost and this action cannot be undone.',
          },
          actions: {
            confirm: {
              plural: 'Confirm deletions',
              singular: 'Confirm deletion',
            },
          },
        },
        deactivation: {
          iconDescription: 'Attention!',
          title: {
            plural: 'Are you sure you want to deactivate the selected cost centers?',
            singular: 'Are you sure you want to deactivate this cost center?',
          },
          message: {
            plural:
              'By deactivating them, they will no longer appear in the cost center listing when posting expenses.',
            singular: 'By deactivating it, it will no longer appear in the cost center listing when posting expenses.',
          },
          actions: {
            confirm: {
              plural: 'Confirm deactivations',
              singular: 'Confirm deactivation',
            },
          },
        },
      },
    },
    advancesAccounting: {
      statusCard: {
        filter: 'Filter',
        removeFilter: 'Remove filter',
        label: {
          open: 'Open',
          finished: 'Finished',
          submitted: 'Submitted',
          reproved: 'Reproved',
          approved: 'Advances approved',
          pending: 'Pending',
        },
      },
      filters: {
        status: 'Status',
        paymentMethod: 'Payment method',
        createdDate: 'Created date',
        approver: 'Approver',
        createdBy: 'Created by',
        referenceId: 'Search by code',
        statusLabel: {
          open: 'Open',
          finished: 'Finished',
          submitted: 'Submitted',
          reproved: 'Reproved',
          approved: 'Approved',
          pending: 'Pending',
        },
      },
    },
    advancesSettings: {
      general: {
        title: 'General Settings',
        caption: 'Configure basic settings for down payment requests.',
        fields: {
          allowsMultipleAdvancesPerReport: 'Allow linking more than one advance per report.',
          approveOwnAdvance: 'Allow users to approve their own down payments.',
          includeAdvanceBankDetails: 'Include bank details of the advance creator.',
          enableAdvanceBatch: 'Enable batch advance.',
          allowsLinkingExpenseReturnOfAdvanceWithoutLinkingAdvances:
            'Allow linking "return of advance" expenses without having advance linked to the report.',
        },
      },
      code: {
        title: 'Code',
        caption: 'Define a code that will facilitate the reconciliation of advance payment requests on the platform.',
        fields: {
          prefixValue: 'Prefix',
          startValue: 'Starting number',
        },
      },
      exportPDF: {
        title: 'Export Preferences (PDF)',
        caption: 'Include in the exported PDF document:',
        fields: {
          allowIncludeBankDetailsPdf: "Include creator's bank details in the report",
          allowIncludeSignaturePdf: 'Include signatures in the report',
          allowIncludeReportHistoryPdf: 'Include report history',
        },
      },
      form: {
        title: 'Field customization',
        caption: 'Customize the fields that will be displayed and required in the advance payment request.',
        titleField: 'Field enabled',
        titleRequired: 'Mandatory completion',
        fields: {
          costCenter: 'Cost center',
          approver: 'Approver',
          report: 'Report',
          client: 'Client',
          project: 'Project',
          currency: 'Currency',
          paymentMethod: 'Form of payment',
        },
      },
      feedbacks: {
        success: 'Changes made successfully!',
        error: 'An error occurred while making the request!',
      },
    },
    mandatoryFieldInfo: {
      plural: 'Mandatory fields',
      singular: 'Mandatory field',
    },
    popoverDelegateUsers: {
      apply: 'Apply',
      goBack: 'Go back',
      empty: 'No people found!',
      clear: 'Clear',
      delegateProfiles: 'Delegate profiles',
    },
    policy: {
      gridPage: {
        title: 'Políticas',
        caption: 'Gerencie as políticas da sua empresa.',
        add: {
          label: 'Adicionar política',
          options: {
            expensePolicy: 'Política de Despesas',
            corporateCardPolicy: 'Uso do Cartão Corporativo',
          },
        },
      },
      register: {
        title: {
          add: 'Add policy',
          edit: 'Edit policy',
        },
        ocr: {
          title: 'Reading receipt',
          caption: 'Configure receipt reading rules.',
          enableRulesOnReceiptReading: 'Enable rules for automatic reading of invoices',
          sections: {
            validations: {
              title: 'Information validated by the reader',
              caption: 'Select what information will be needed to determine the validity of an invoice',
              checks: {
                establishmentName: 'Establishment name',
                address: 'Address',
                registrationNumber: 'CNPJ',
                invoiceDate: 'Invoice issue date',
                invoiceKey: 'Invoice Key',
                totalInvoiceValue: 'Total value of the invoice',
                invoiceItemsDescription: 'Description of the invoice items',
                invoiceItemsValue: 'Unit value of the invoice items',
                invoiceItemsQuantity: 'Quantity of invoice items',
                invoiceItemsTax: 'Tax value of invoice items',
                invoiceItemsMeasurementUnit: 'Unit of measure for invoice items',
                invoiceTotalItemsValue: 'Total value of invoice items',
              },
              violationBehaviour:
                'If any information from those selected is not identified, determine what will happen:',
            },
            manualChanges: {
              title: 'Manual change detection',
              caption: 'Define what will happen when the system detects a change made when reading the invoice.',
            },
            compliantRules: {
              title: 'Personal Items and Compliance Rules',
              caption:
                'Set whether you want to block or generate a violation when there are personal items such as drinks and cigarettes on the invoices.',
            },
          },
          behaviour: {
            generateViolation: 'Generate violation',
            blockSubmit: 'Block submission',
          },
          selectAll: 'Select all',
        },
        categories: {
          title: 'Categories',
          caption: 'Select the categories that can be included in expenses and, if necessary, add spending limits.',
          direct: 'You can also create new categories in: ',
          linkDirect: 'Expenses > Expense Settings > Categories',
          field: {
            label: 'Active categories in the policy',
          },
          optional: 'Optional',
          actionTitle: 'Add custom spending limits',
          actionCaption: 'Set custom limits for expenses posted to the categories selected above.',
        },
        conditions: {
          when: 'When',
          and: 'and',
          itIs: 'it is',
          conditionOptions: {
            project: 'Project',
            user: 'User',
            role: 'Job position',
            subsidiary: 'Business Unit (Branch)',
            area: 'Department',
            company: 'Company',
          },
          fields: {
            conditionLabel: 'Condition',
            mandatory: 'Mandatory completion',
          },
          addConditional: 'Add conditional',
          infos: {
            title: 'Conditionals of the policy',
            subTitle: 'Priority level',
            caption: 'Understand how policies work according to priority level.',
            Paragraph1:
              'When there is more than one policy applied to the same person, the most restrictive one is prioritized.',
            Paragraph2:
              'For example, a policy defined for a project takes priority over a general company policy or one created for a specific position. This way, when the user posts the expense to the project in question, the rules of this policy will be applied.',
            Paragraph3:
              'In the case of multiple criteria, the policy will belong to the criterion with the highest priority level.',
            Paragraph4: 'A policy with project criteria, and position, will be a project policy.',
          },
        },
        mileage: {
          title: 'Mileage',
          caption: 'Enable settings and add mileage rates.',
        },
        advances: {
          title: 'Advances',
          caption: 'Define down payment settings.',
          settings: {
            simultaneous: {
              title: 'Simultaneous advance',
              caption: 'Allows you to create and process more than one advance at the same time.',
            },
            residual: {
              title: 'Residual advance',
              caption:
                'When the bill is less than the advance, a residual advance is generated to cover the difference.',
            },
          },
          limits: {
            title: 'Limits',
            caption: 'Set the down payment limits according to each currency.',
            actions: {
              addLimit: 'Add limit',
            },
            item: {
              currency: 'Currency',
              value: 'Value',
            },
            modal: {
              title: {
                create: 'Add advance value limit',
                edit: 'Edit advance value limit',
              },
              caption: 'Add a limit for requesting advance payments depending on the currency.',
              actions: {
                cancel: 'Cancel',
                add: 'Add',
                save: 'Save',
              },
              fields: {
                currency: 'Currency',
                value: 'Limit value',
              },
            },
            feedbacks: {
              advanceLimitDeletedSuccessfully: 'The limit has been deleted successfully',
              errorDeletingAdvanceLimit: 'An error occurred while deleting the limit',
              advanceLimitSavedSuccessfully: 'Limit saved successfully',
              errorSavingAdvanceLimit: 'An error occurred while saving the limit',
            },
          },
        },
        breadcrumb: {
          customizations: 'Customizations',
        },
        steps: {
          basicInformation: {
            title: 'Basic information',
            caption: "Enter the basic policy information, and if necessary, add your company's policy document.",
            fields: {
              namePolicy: 'Policy name',
              description: 'Descrição',
              mandatory: 'Mandatory completion',
              charactersInfo: 'Enter at least 3 characters!',
            },
            documentSection: {
              title: 'Policy document',
              caption:
                "Upload your company's policy document and it will be made available to everyone for consultation.",
              optional: 'Optional',
              field: 'Policy',
              documentName: 'Policies',
            },
          },
          policyProperties: {
            title: 'Policy properties',
            caption:
              'Select when the policy will be applied, and which rules will determine application. No rules are mandatory, but the policy must contain at least one rule.',
            policyConditions: {
              title: 'Policy application conditions',
              caption:
                'Define, through the selected conditionals, to which groups of people this policy will be applied. The more specific the conditionals, the more priority the policy will be.',
              knowMore: 'Know more',
            },
            policyRules: {
              title: 'Policy enforcement rules',
              rules: {
                category: 'Categories',
                mileage: 'Mileage',
                advances: 'Advances',
                receiptReading: 'Receipt reading',
              },
            },
          },
          policySummary: {
            title: 'Summary',
            caption:
              'Review your policy information, only proceed after confirming the settings. If necessary, return to editing.',
          },
        },
        footer: {
          cancel: 'Cancel',
          back: 'to back',
          continue: 'Continue',
          finish: 'Finish',
        },
        feedbacks: {
          byId: {
            error: {
              title: 'An error occurred while opening the policy',
              message: 'Please try again in a few moments.',
            },
          },
        },
      },
      policyGrid: {
        all: 'All',
        byLevel: 'By level',
        search: 'Search by name, policy description',
        byLevelDescription1: 'View all policies according to hierarchy level.',
        byLevelDescription2:
          'In case of conflict between policies at the same level, you can change the priority level between them.',
        feedbacks: {
          list: {
            error: {
              title: 'Unable to get policy list',
              message: 'Please try again in a few moments.',
            },
          },
        },
        header: {
          name: 'Policy name',
          description: 'Description',
          level: 'Level',
          updatedAt: 'Last update',
          status: 'Status',
          actions: 'Actions',
        },
        columns: {
          status: {
            on: 'on',
            off: 'off',
          },
          actions: {
            seeDetails: 'View policy details',
            edit: 'Edit policy',
            duplicate: 'Duplicate policy',
            export: 'Export policy',
            delete: 'Delete policy',
          },
        },
        filters: {
          level: {
            label: 'Level',
            options: {
              project: 'Project',
              user: 'User',
              role: 'Job position',
              subsidiary: 'Business unit (Branch)',
              area: 'Department',
              company: 'Company',
            },
          },
          status: {
            label: 'Status',
            options: {
              active: 'Active',
              inactive: 'Inactive',
            },
          },
          lastUpdate: {
            label: 'Last update',
          },
        },
      },
      policyDetails: {
        drawer: {
          title: 'Policies',
          caption: "View details of your company's policies and when they apply.",
          active: 'Active',
          inactive: 'Inactive',
          undocumented: 'This policy has no document!',
          emptyPolicies: 'No policies available.',
        },
      },
      create: {
        feedback: {
          error: {
            title: 'An error occurred while creating the policy',
            message: 'Please try again in a few moments.',
          },
          success: {
            title: 'Policy created successfully',
            message: 'Continue with the next configuration steps.',
          },
        },
      },
      update: {
        feedback: {
          error: {
            title: 'An error occurred while updating policy data',
            message: 'Please try again in a few moments.',
          },
          success: {
            title: 'Policy updated successfully',
            message: 'If you want to change other data, continue with the next steps!',
          },
        },
      },
      duplicate: {
        feedback: {
          error: {
            title: 'An error occurred while duplicating policy',
            message: 'Please try again in a few moments.',
          },
          success: {
            message: 'Successfully duplicated policy!',
          },
        },
      },
      toggle: {
        plural: {
          feedback: {
            error: {
              title: 'Unable to change status of selected policies',
              message: 'Please try again in a few moments.',
            },
            success: {
              message: 'Policy status changed successfully!',
            },
          },
        },
        singular: {
          feedback: {
            error: {
              title: 'Unable to change policy status',
              message: 'Please try again in a few moments.',
            },
            success: {
              message: 'Policy status changed successfully!',
            },
          },
        },
      },
      delete: {
        plural: {
          modal: {
            title: 'Are you sure you want to delete the selected policies?',
            message: 'When deleting them, all data related to them will be lost',
            confirm: 'Confirm deletions',
          },
          feedback: {
            error: {
              title: 'Unable to delete selected policies',
              message: 'Please try again in a few moments.',
            },
            success: {
              message: 'Policies deleted successfully!',
            },
          },
        },
        singular: {
          modal: {
            title: 'Are you sure you want to delete this policy?',
            message: 'When deleting, all data related to it will be lost',
            confirm: 'Confirm deletion',
          },
          feedback: {
            error: {
              title: 'Unable to delete policy',
              message: 'Please try again in a few moments.',
            },
            success: {
              message: 'Policy deleted successfully!',
            },
          },
        },
      },
      disable: {
        plural: {
          modal: {
            title: 'Are you sure you want to disable the selected policies?',
            message:
              'When you deactivate them, the policies will be unavailable, but they can be activated whenever you want.',
            confirm: 'Confirm deactivations',
          },
          feedback: {
            error: {
              title: 'Unable to disable selected policies',
              message: 'Please try again in a few moments.',
            },
            success: {
              message: 'Policies disabled successfully!',
            },
          },
        },
        singular: {
          modal: {
            title: 'Are you sure you want to disable this policy?',
            message: 'When deactivated, the policy becomes unavailable, but can be activated whenever you want.',
            confirm: 'Confirm deactivation',
          },
          feedback: {
            error: {
              title: 'Unable to disable policy',
              message: 'Please try again in a few moments.',
            },
            success: {
              message: 'Policy deactivated successfully!',
            },
          },
        },
      },
    },
    pages: {
      subsidiaryRegister: {
        actions: {
          cancel: 'Cancel',
          save: 'Register',
          goBack: 'Go back',
          continue: 'Continue',
        },
        feedbacks: {
          savingSubsidiary: 'Saving subsidiary data',
          create: {
            success: 'Create subsidiary successfully!',
            error: 'An error occurred when creating subsidiary',
          },
          update: {
            success: 'Updated subsidiary successfully!',
            error: 'An error occurred when updating subsidiary',
          },
          gettingOne: {
            error: 'Error while trying to retrieve subsidiary data',
          },
        },
        assignUsersGrid: {
          allPeople: 'All people ({{selected}})',
          selectedForCostCenter: 'Selected to launch in this business category ({{selected}})',
          all: 'All ({{selected}})',
          selected: 'Selected ({{selected}})',
          header: {
            name: 'Name',
          },
        },
        breadcrumb: {
          manualAddSubsidiary: 'Register company',
          manageCompany: 'Manage company',
          subsidiary: 'Subsidiary',
          editSubsidiary: 'Edit subsidiary',
        },
      },
      analytics: {
        title: 'Data Report',
      },
      subsidiaries: {
        feedbacks: {
          update: {
            success: 'Updated subsidiary successfully!',
            error: 'An error occurred when updating subsidiary',
          },
          subsidiary: 'Subsidiary: ',
        },
      },
      clientRegister: {
        title: 'Add client manually',
        cancel: 'Cancel',
        continue: 'Continue',
        conclude: 'Ok',
        creatingClient: 'Creating client...',
        updatingClient: 'Updating client...',
        bulkImportLoading: 'Loading clients...',
        feedbacks: {
          gettingMany: {
            error: 'Error when load projects',
          },
          gettingOne: {
            error: 'Error when load the project',
          },
          savingProject: 'Saving project',
          update: {
            success: 'Project successfully created',
            error: 'Error when updating project',
          },
          create: {
            success: 'Project successfully created',
            error: 'Error when creating project',
          },
          toggleStatus: {
            success: 'Project successfully created',
            error: 'Error when updating project',
          },
          delete: {
            success: 'Project successfully created',
            error: 'Error when deleting project',
          },
        },
      },
      setupOnboarding: {
        title: 'Configurar Flash Despesas',
        steps: {
          categories: 'Categorias',
          limits: 'Limites',
          communication: 'Comunicação',
        },
        skipConfiguration: 'Pular configurações',
        backStep: 'Voltar',
        nextStep: 'Avançar',
      },
      reportDetails: {
        title: 'Report details',
        addExpense: 'Add expenses',
        addKM: 'Add kilometers',
        addAdvance: 'Add advance',
        attachExpense: 'Attach expense',
        attachAdvance: 'Attach advance',
        comeBack: 'Come back',
        reopenReport: 'Reopen report',
        sendReport: 'Send report',
        cancel: 'Cancel',
      },
      expenses: {
        pageTitle: 'Accountability',
        expenses: 'Expenses',
        pendingExpenses: 'Pending expenses',
        add: 'Add',
        mileage: 'Mileage',
        latestReports: 'Latest reports',
        seeAllReports: 'See all reports',
      },
      sharedExpenses: {
        add: 'Add expenses',
        addCorporate: 'Add corporate expenses',
        addCompanyPayable: 'Vendor expenses',
        expenses: 'Manual expense',
        mileage: 'Mileage Expense',
        goToConfiguration: 'Go to configuration',
        goToDelegationSettings: 'Go to delegation settings',
        policy: 'View policies',
        delegateProfiles: 'Delegate profiles',
        addVendor: 'Add vendor',
        tabs: {
          transaction: 'Transactions without accounting',
          expenses: 'Corporate expenses',
          vendorExpenses: 'Vendor expenses',
        },
        viewingAs: 'Viewing how',
        backToMyProfile: 'Return to my profile',
      },
      expenseRegister: {
        newExpense: 'New expense',
        editExpense: 'Edit expense',
        cancel: 'Cancel',
        comeBack: 'Come back',
        continue: 'Continue',
        creatingExpense: 'Creating expense...',
        updatingExpense: 'Updating expense...',
        reportNotFound: 'Report not found',
        selectReport: 'Select report',
        addMileage: 'Add mileage',
        editMileage: 'Edit mileage',
        requiredFields: 'Required fields',
        requiredFieldsMgs: 'Please fill in the indicated fields!',
      },
      projectRegister: {
        title: 'Add project by file',
        project: 'Project',
        manualAddProject: ['Add project manually', 'Assign people'],
        registerInstructions: [
          'Insert the customer data, and configure the people who can post expenses to him.',
          'Choose which people can register expenses in this project.',
        ],
        editProject: 'Edit project',
        breadcrumb: {
          manageCompany: 'Manage company',
          project: 'Projects',
        },
        sections: {
          generalData: 'General Data',
        },
        fields: {
          projectName: 'Project name',
          client: 'Client',
          costCenter: 'Cost center',
          startAt: 'Start Date',
          endAt: 'End date',
          accountable: 'Accountable',
          code: 'Code',
          codeHelperText:
            'Enter the code used by your company for better control. The use of this code facilitates the reconciliation of reports exported by our platform',
          activeProject: {
            title: 'Activate project',
            description: 'When active, the project appears as an option when entering an expense.',
          },
        },
        actions: {
          cancel: 'Cancel',
          continue: 'Continue',
        },
        feedbacks: {
          gettingMany: {
            error: 'Error when load projects',
          },
          gettingOne: {
            error: 'Error when load the project',
          },
          savingProject: 'Saving project',
          update: {
            success: 'Project successfully created',
            error: 'Error when updating project',
          },
          create: {
            success: 'Project successfully created',
            error: 'Error when creating project',
          },
          toggleStatus: {
            success: 'Project successfully created',
            error: 'Error when updating project',
          },
          delete: {
            success: 'Project successfully created',
            error: 'Error when deleting project',
          },
        },
        validation: {
          startDateMustBeGreaterThenToday: 'Start date must be greater than today',
          startDateMustBeLessThenEndDate: 'Start date must be less than end date',
        },
      },
      advanceRegister: {
        title: {
          toView: 'Advance',
          create: 'Add advance',
          edit: 'Edit advance',
        },
        advances: 'Advances',
        approvers: 'Approvers',
        description: 'Fill in the information below to request an advance.',
        toViewDescription: 'Advance request details.',
        fields: {
          currency: 'Currency',
          value: 'Value',
          paymentType: 'Form of payment',
          client: 'Client',
          project: 'Project',
          costCenter: 'Cost center',
          report: 'Report',
          approver: 'Approver',
          advanceDate: 'Advance date',
          description: 'Description',
          requester: 'Requester',
        },
        actions: {
          cancel: 'Cancel',
          create: 'Request advance',
          edit: 'Save advance',
          goBack: 'Go back',
          approve: 'Approve advance',
          reprove: 'Reject advance',
          reopen: 'Reopen advance',
          pay: 'Pay advance',
          submitForIntegration: 'Submit for integration',
          tooltip: {
            accounting: 'This action can only be taken when the advance has an approved status!',
            approval: 'This action can only be taken when the advance is in submitted status!',
          },
        },
        feedbacks: {
          save: 'Changes made successfully!',
          create: 'Advance created successfully!',
          error: 'An error occurred. Please try again.',
          attach: 'Linking the advance to the report {{ reportName}} successfully completed!',
        },
        validation: {
          requiredField: 'Required field!',
        },
      },
      advances: {
        advance: 'Advance',
        attach: 'Attach',
        addButton: 'Add advance',
        add: 'Add',
        title: 'Advances',
        caption: 'Add and monitor your advance requests.',
        approval: {
          feedbacks: {
            statusSuccessMessage: {
              plural: 'Status successfully changed',
              singular: 'Status successfully changed',
            },
          },
        },
      },
      costCenterRegister: {
        steps: {
          header: {
            generalData: 'General data',
            users: 'Assign users',
          },
          pageContent: [
            {
              title: 'General data',
              sectionTitle: 'General data',
              subTitle: 'Enter the basic cost center data.',
            },
            {
              title: 'Assign people',
              sectionTitle: 'Select people',
              subTitle: 'Configure the people belonging to the cost center',
            },
          ],
        },
        assignUsersGrid: {
          allPeople: 'All people ({{selected}})',
          selectedForCostCenter: 'Selected to post to this cost center ({{selected}})',
          all: 'All ({{selected}})',
          selected: 'Selected ({{selected}})',
          header: {
            name: 'Name',
          },
        },
        costCenter: 'Cost center',
        manualAddCostCenter: 'Add cost center manually',
        registerInstructions: 'Enter the cost center data, and configure the people belonging to it.',
        editCostCenter: 'Edit cost center',
        breadcrumb: {
          manageCompany: 'Manage company',
          costCenter: 'Cost centers',
        },
        sections: {
          generalData: 'General data',
        },
        fields: {
          costCenterName: 'Cost center name',
          responsible: 'Responsible',
          department: 'Department',
          project: 'Project',
          groupCompany: 'Group companies',
          code: 'Code',
          codeHelperText:
            'Enter the code used by your company for better controlInsert the code used by your company for better control. The use of this code facilitates the reconciliation of reports exported by our trole platform.',
          activeCostCenter: {
            title: 'Activate cost center',
            description: 'When active, this cost center appears as an option when posting an expense.',
          },
        },
        actions: {
          cancel: 'Cancel',
          continue: 'Continue',
          save: 'Save',
          goBack: 'Go back',
        },
        feedbacks: {
          savingCostCenter: 'Saving cost center',
          costCenterCreatedSuccess: 'Cost center successfully created!',
          costCenterCreatedAndEditError: 'There was an error making your request!!',
          costCenterEditSuccess: 'Cost center successfully edited!',
          costCenterStatusSuccess: {
            plural: 'Status of cost centers successfully changed!',
            singular: 'Cost center status changed successfully!',
          },
          costCenterDeleteSuccess: {
            plural: 'Cost centers deleted successfully!',
            singular: 'Cost center deleted successfully!',
          },
        },
        bulkImport: {
          title: 'Add cost center by file',
        },
      },
      reports: {
        title: 'Reports',
        add: 'Add report',
      },
      expenseDetails: {
        expense: 'Expense',
        breadcrumb: {
          expenses: 'Expenses',
          expenseDetails: 'Expense details',
          originDestination: 'Mileage details - O&D',
          odometer: 'Mileage Details - Odometer',
        },
      },
      financialReports: {
        title: 'Accounting',
        Accountability: {
          title: 'Accountability',
          tabs: {
            pendingAccounts: 'Pending Accounting',
            pendingAccountability: 'Pending Accountability',
            filterTitle: 'Filter for',
          },
          errorBoundary: {
            moduleName: 'company Payables',
            title: 'Error trying to load expense banner with supplier',
          },
        },
      },
      financialReportDetails: {
        reprove: 'Reprove',
        approve: 'Approve',
        repay: 'Repay',
        comeBack: 'Come back',
        integration: 'Send to integration',
        cancelIntegration: 'Cancel integration',
      },
      customizations: {
        title: 'Preferences',
      },
      financialAdvances: {
        title: 'Advances',
      },
      launchMileageExpense: {
        title: {
          newMileageExpense: 'New mileage expense',
          editMileageExpense: 'Edit mileage expense',
        },
        breadcrumbs: {
          expenses: 'Expenses',
          addMileage: 'Add mileage',
          editMileage: 'Edit mileage',
        },
      },
      subscriptionConfirmation: {
        title: 'Check your subscription information',
        caption:
          'Check the services your company is purchasing, review the commercial conditions and sign the contract to obtain greater agility, control and security in managing your company expenses!',
        loading: {
          title: 'Loading your contract information',
          message:
            "We have prepared the best commercial conditions to make your company's expense management even more efficient",
        },
        breadcrumbs: {
          start: 'Start',
          getToKnowFlashExpense: 'Get to know Flash Expense',
          confirmYourSubscription: 'Confirm your subscription',
        },
        actionsPage: {
          seeMore: 'See more',
          toGoBack: 'To go back',
          cancel: 'Cancel',
          confirm: 'Confirm',
        },
        tooltip: {
          confirm: 'You need to read and accept the commercial conditions to proceed.',
        },
      },
      approvals: {
        title: 'Approvals',
      },
    },
  },
};

export default translations;
