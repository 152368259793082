import { useContext } from 'react';
import { useAllPaymentMethods } from '@containers/Expenses/hooks/useAllPaymentMethods';
import { useUsersSelectionList } from '@containers/Expenses/hooks/useUsersSelectionList';
import { Table } from '@flash-tecnologia/hros-web-ui-v2';
import { useRowsPerPageOptions } from '@molecules/GridPagination/kooks/useRowsPerPageOptions';
import { PendingAccountContext } from '@shared/contexts/PendingAccountContext/PendingAccountContext';
import { useTranslate } from '@shared/hooks/translate/useTranslate';

import { PendingExpensesTableFilter } from './PendingExpensesTableFilter';
import { TableHelper } from './TableHelper';

export function PendingExpensesTable() {
  const { usersQuantity, pagination, isLoading, setPagination } = useContext(PendingAccountContext);
  const t = useTranslate('pages.financialReports.Accountability.tabs');
  const { tableHelper } = TableHelper();
  const { rowsPerPageOptions } = useRowsPerPageOptions({ maxRows: 50, intervalItems: 10 });
  const { isLoading: paymentMethodsIsLoading } = useAllPaymentMethods();
  const { users, isLoading: isLoadingUser } = useUsersSelectionList();
  const dataFilters = PendingExpensesTableFilter(users);

  return (
    <Table.Root data-testid="accounting-pending-expenses-table">
      {!paymentMethodsIsLoading && !isLoadingUser && <Table.Filters label={t('filterTitle')} filters={dataFilters} />}

      <Table.Grid.Root loading={isLoading}>
        <Table.Grid.Header getHeaderGroups={tableHelper.getHeaderGroups} />
        {tableHelper.rows?.map((row, index) => (
          <Table.Grid.Row key={index + row.id} row={row} />
        ))}
      </Table.Grid.Root>
      {!isLoading && (
        <Table.Pagination
          count={usersQuantity}
          onPaginationChange={({ pageSize, pageNumber }) => setPagination({ ...pagination, pageSize, pageNumber })}
          pagination={pagination}
          pageSizeOptions={rowsPerPageOptions}
        />
      )}
    </Table.Root>
  );
}
