import { createContext, ReactNode, useEffect, useState } from 'react';
import { useUsersPendingExpenses } from '@containers/Financial/hooks/dataSources/useUsersPendingExpenses';
import { PaginationState } from '@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination';

import { IPendingAccountContext } from './types';

export const PendingAccountContext = createContext<IPendingAccountContext>(null);

interface IPendingAccountContextProviderProps {
  children: ReactNode;
}

export interface IFilters {
  userIds?: number[];
  paymentMethods?: string[];
  createdFrom?: string | Date;
  createdTo?: string;
  amountFrom?: number | undefined;
  amountTo?: number | undefined;
  isCorporateCard?: boolean | undefined;
  hasViolation?: boolean | undefined;
}

export const MAX_RANGE_VALUE = 10000;
export const MIN_RANGE_VALUE = 0;
export const STEP_RANGE_VALUE = 100;

export const PendingAccountContextProvider = ({ children }: IPendingAccountContextProviderProps) => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageNumber: 1,
    pageSize: 10,
  });

  const [filters, setFilters] = useState({
    userIds: [] as number[],
    paymentMethods: [] as string[],
    createdFrom: '',
    createdTo: '',
    amountFrom: undefined,
    amountTo: undefined,
    isCorporateCard: undefined,
    hasViolation: undefined,
  });

  const {
    response: { users, total: usersQuantity },
    isLoading,
  } = useUsersPendingExpenses({
    filters: {
      ...filters,
      page: pagination.pageNumber,
      pageLimit: pagination.pageSize,
    },
  });

  useEffect(() => {
    setPagination(prev => ({
      ...prev,
      pageNumber: 1,
    }));
  }, [pagination.pageSize]);

  return (
    <PendingAccountContext.Provider
      value={{
        users,
        filters,
        usersQuantity,
        pagination,
        isLoading,
        setPagination,
        setFilters,
      }}>
      <>{children}</>
    </PendingAccountContext.Provider>
  );
};
